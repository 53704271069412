import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import TeamStats from "./TeamStats";
import TeamStatsCIQ from "./TeamStatsCIQ";
import { Route, Switch } from "react-router-dom";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  border-bottom: 1px solid #dadde1;
  box-sizing: border-box;
  font-size: 1.5em;
  width: 100%;
  padding: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  /* padding: 1em; */
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 100%;
`;

const Lineups = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 100%;
`;

const Centre = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  background-color: #0e1219;
`;

const SubsConfirm = styled.div`
  display: flex;
`;

const TeamSubs = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
`;
const Button = styled.div`
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;

  margin-top: 0.5em;
  padding: 0.5em;
`;

export default function Game({ game }) {
  return (
    <Main>
      {game && (
        <Content>
          <ActionPanel>
            <Switch>
              <Route path="/fruit/combatiq">
                <TeamStatsCIQ game={game} />
              </Route>
              <Route path="/">
                <TeamStats game={game} />
              </Route>
            </Switch>
          </ActionPanel>
        </Content>
      )}
    </Main>
  );
}
