import { getApolloContext } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState, useEffect, useContext } from "react";

const TEAMS = gql`
  query Teams {
    teams {
      _id
      name
      code
      variables
    }
  }
`;

const COMPETITIONS = gql`
  query Competitions {
    competitions {
      _id
      name
      description
      opta_ID
      formation
      code
      country
      country_code
      competition_type
      active_fight {
        _id
      }
    }
  }
`;

const GAMES = gql`
  query Games {
    games {
      oval_ID
      _id
      time
      description
      date
      competition {
        oval_ID
        name
        competition_type
        badge
        secondary_badge
        oval_ID
      }
      home_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
        nationality {
          code
        }
      }
      away_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
        nationality {
          code
        }
      }
      live_data
      number_of_rounds
      gold_fight
      championship_bar
      round_minutes
      title
    }
  }
`;

const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      date
      stadium {
        opta_name
        name
      }
      competition {
        oval_ID
        name
        competition_type
        badge
        secondary_badge
      }
      lineup {
        home {
          squad {
            _id
            shirt_number
            opta_shirt_number
            position
            stats
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                code
                name
              }
              image
              stories
              oval_ID
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
        }
        away {
          squad {
            _id
            shirt_number
            opta_shirt_number
            position
            stats
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper

              nationality {
                _id
                code
                name
              }
              image
              stories
              oval_ID
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            stats
            player {
              first_name
              last_name
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            stats
            player {
              first_name
              last_name
            }
          }
          order
        }
      }
      home_fighter {
        _id
        first_name
        last_name
        image
        image_2
        stories
        shorts_colour
        nationality {
          code
        }
      }
      away_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
        nationality {
          code
        }
      }
      live_data
      number_of_rounds
      gold_fight
      championship_bar
      round_minutes
      title
    }
  }
`;

const UPDATE_GAME = gql`
  mutation UpdateGame(
    $id: String!
    $home_player_stats: JSON
    $away_player_stats: JSON
    $live_data: JSON
  ) {
    updateGame(
      id: $id
      home_player_stats: $home_player_stats
      away_player_stats: $away_player_stats
      live_data: $live_data
    ) {
      live_data
    }
  }
`;

const SQUAD_BY_TEAM = gql`
  query Squad($teamId: String, $seasonId: String) {
    squadByTeam(teamId: $teamId, seasonId: $seasonId) {
      _id
      active
      type
      shirt_number
      opta_shirt_number
      position
      player {
        _id
        opta_ID
        first_name
        last_name
        first_name_upper
        last_name_upper
        title
        nationality {
          _id
          code
          name
        }
        image
        stories
      }
      team {
        name
        short_name
      }
    }
  }
`;

const STANDINGS = gql`
  query Standings {
    standings {
      _id
      oval_comp_ID
      oval_season_ID
      data
    }
  }
`;

const APIContext = React.createContext([{}, () => {}]);

const APIProvider = (props) => {
  const { client } = useContext(getApolloContext());
  let serverAddress = window.dataUrl.replace("ws", "http");

  if (serverAddress.slice(-1) === "/") {
    serverAddress = serverAddress.slice(0, -1);
  }

  function getSeason(seasonId) {
    return fetch(serverAddress + "/seasons/" + (seasonId ? seasonId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }
  function getOfficial(officialId) {
    return fetch(serverAddress + "/officials/" + (officialId ? officialId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }
  function getStadium(stadiumId) {
    return fetch(serverAddress + "/stadiums/" + (stadiumId ? stadiumId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getStandings() {
    return client
      .query({
        query: STANDINGS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.error(err));
  }

  function getCompetition(compId) {
    return client
      .query({
        query: COMPETITIONS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.competitions;
      })
      .catch((err) => console.error(err));
  }

  function updatePlayer(player) {
    return fetch(serverAddress + "/players/" + player._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(player),
    });
  }
  function updateLineup({ data, fixture, team }) {
    return fetch(serverAddress + "/games/" + fixture._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { [`live_data.${team}_team_lineup`]: data },
      }),
    });
  }

  function getSquads() {
    return fetch(serverAddress + "/squads/")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getTeams({ team }) {
    return client
      .query({
        query: TEAMS,
      })
      .then((response) => {
        return response.data.teams;
      })
      .catch((err) => console.error(err));
  }

  function getFixture({ fixtureId }) {
    return client
      .query({
        query: GAMES,
      })
      .then((response) => {
        return response.data.games.find((game) => game._id === fixtureId);
      })
      .catch((err) => console.error(err));
  }

  function getFixtures({ competition, date }) {
    return client
      .query({
        query: GAMES_BY_DATE,
        variables: { date: date + "Z" },
      })
      .then((response) => {
        return response.data.gamesByDate;
      })
      .catch((err) => console.error(err));
  }

  function updateGame({ id, home_player_stats, away_player_stats, live_data }) {
    return client
      .mutate({
        mutation: UPDATE_GAME,
        variables: {
          id,
          home_player_stats,
          away_player_stats,
          live_data,
        },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function getSquadPlayers({ teamId, seasonId, coach }) {
    return client
      .query({
        query: SQUAD_BY_TEAM,
        fetchPolicy: "network-only",
        variables: {
          teamId: teamId,
          seasonId: seasonId,
        },
      })
      .then((response) => {
        return response.data.squadByTeam;
      })
      .catch((err) => console.error(err));
  }

  function getSquad({ teamId, seasonId }) {
    return getSquadPlayers({ teamId: teamId, seasonId: seasonId });
  }

  function getLineup({ fixtureId, team }) {
    return getFixture({ fixtureId: fixtureId }).then((fixture) => {
      let team_lineup = fixture.live_data[`${team}_team_lineup`];
      let url = "";

      return getSquadPlayers({
        teamId: fixture[team + "_team"],
        seasonId: fixture.season,
      }).then((squadPlayers) => {
        if (team_lineup && team_lineup.length > 1) {
          url =
            serverAddress +
            "/players/?$orderby=last_name&$filter=_id $in " +
            team_lineup.map((d) => d.player).join(",");
        } else if (team_lineup && team_lineup.length > 0) {
          url = serverAddress + "/players/" + team_lineup[0].player;
        }

        if (url === "") {
          return Promise.resolve();
        }

        return fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (Array.isArray(data)) {
              return team_lineup.map((l) => {
                return {
                  ...l,
                  ...squadPlayers.find((p) => p.player === l.player),
                  ...data.find((p) => p._id === l.player),
                  order: l.order,
                };
              });
            } else {
              return team_lineup.map((l) => {
                return { ...l, ...data, order: l.order };
              });
            }
          });
      });
    });
  }

  function getPlayersBySearch({ search, count, limit, page }) {
    let url =
      serverAddress + "/players?$filter=last_name $regex '^" + search + "'";
    if (count) {
      url += "&$count";
    }
    if (limit) {
      url += "&$limit=" + limit;
    }
    if (page) {
      url += "&$skip=" + page;
    }

    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function createFixture({ fixture }) {
    let newFixture = {
      ...fixture,
      live_data: { home_score: 0, away_score: 0 },
    };
    return fetch(serverAddress + "/games/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newFixture),
    });
  }

  function deleteFixture({ fixtureId }) {
    return fetch(serverAddress + "/games/" + fixtureId, {
      method: "DELETE",
    });
  }

  function getOfficial(officialId) {
    return fetch(serverAddress + "/officials/" + (officialId || ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updateGameOfficial({ fixtureId, official, type }) {
    return fetch(serverAddress + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { [type]: official._id },
      }),
    });
  }

  function updateHeadCoach({ teamId, coach }) {
    return fetch(serverAddress + "/teams/" + teamId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { coach: coach },
      }),
    });
  }
  function getPlayer({ playerId }) {
    return fetch(serverAddress + "/players/" + (playerId || ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updatePlayer(player) {
    if (player._id) {
      player = { ...player, nationality: player.nationality._id };
      return fetch(serverAddress + "/players/" + player._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(player),
      });
    } else {
      return fetch(serverAddress + "/players/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(player),
      });
    }
  }
  return (
    <APIContext.Provider
      value={{
        updatePlayer,
        updateLineup,
        getLineup,
        getFixture,
        getTeams,
        getSquad,
        getPlayersBySearch,
        getSquads,
        createFixture,
        deleteFixture,
        getOfficial,
        updateGameOfficial,
        updateHeadCoach,
        getCompetition,
        getSeason,
        getStadium,
        getOfficial,
        getPlayer,
        getFixtures,
        updatePlayer,
        updateGame,
        getStandings,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
};
export { APIContext, APIProvider };
