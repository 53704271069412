import styled from "styled-components";
import { FaPlusCircle } from "react-icons/fa";

export default {
  primaryBackgroundColour: "#0e1219",
  secondaryBackgroundColour: "#1f222b",
  primaryFontColour: "#cacaca",
  dividerColour: "#2d2d2d",
  hoverColour: "#191b21",
  mainFontSize: ".75em",
  borderColour: "#101010",
  panelColour: "#353a48",
  panelBorderColour: "#4f566b",
  highlightColour: "#db0a41",
  highlightColourLight: "#d63660",
  textItemColour: "#ffeb00",
  imageItemColour: "#00ffa1",
  videoItemColour: "#c000ff",
  groupItemColour: "#00d0ff;",
};

export const H1 = styled.h1`
  font-size: 2em;
  font-weight: 100;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #dadde1;
`;

export const H2 = styled.h2`
  margin-top: 0.5em;
  font-size: 1em;
  font-weight: 100;
`;

export const Button = styled.div`
  background-color: ${(props) => (props.selected ? "#4fe8b0" : "#353a47")};
  color: ${(props) => (props.selected ? "black" : "white")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const RoundButton = styled.div`
  background-color: ${(props) => (props.selected ? "#4fe8b0" : "#353a47")};
  color: ${(props) => (props.selected ? "black" : "white")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 10px;
`;

export const PlusIcon = styled(FaPlusCircle)`
  margin-right: 0.5em;
  font-size: 1.5em;
  display: flex;
  align-items: center;
`;

export const ScrollBar = styled.div`
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.primaryBackgroundColour};
    border-radius: 2px;
    overflow: hidden;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.highlightColourLight};
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.highlightColour};
  }
  /* Track */
  &::-webkit-scrollbar-track:horizontal {
    border-radius: 2px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 2px;
    background-color: #8d1237;
    cursor: pointer;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:horizontal:hover {
    background: ${(props) => props.theme.highlightColour};
  }
`;
