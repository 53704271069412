import React, { useState, useEffect, useContext, useCallback } from "react";
import useLocalStorage from "../Hooks/useLocalStorage";
import parser from "fast-xml-parser";
var options = {
  attributeNamePrefix: "",
  attrNodeName: "attr", //default is 'false'
  textNodeName: "#text",
  ignoreAttributes: false,
  ignoreNameSpace: false,
  allowBooleanAttributes: true,
  parseNodeValue: false,
  parseAttributeValue: false,
  trimValues: false,
  cdataTagName: "__cdata", //default is 'false'
  cdataPositionChar: "\\c",
  parseTrueNumberOnly: false,
  arrayMode: false, //"strict"
  stopNodes: ["parse-me-as-string"],
};
var XMLParser = parser.j2xParser;
const toXML = new XMLParser(options);

const ControlContext = React.createContext([{}, () => {}]);
window.scenesOnAir = [];

window.control_ws = null;
let command_id = 0;
let commands = [];
let show_ID = "";
let show_ID2 = "";
let show_ID3 = "";
let show_ID4 = "";

const ControlProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [scenesOnAir, setScenesOnAir] = useState([]);
  const [usersOnAir, setUserOnAir] = useState([]);
  const [themeName, setThemeName] = useState("rugby");
  const [hyperLink, setHyperLink] = useLocalStorage(
    "hyper_link",
    (window.location.protocol.indexOf("https") > -1 ? "wss" : "ws") +
      "://" +
      window.location.hostname +
      ":6002"
  );
  const [mse, setMSE] = useLocalStorage("mse", "127.0.0.1");
  const [trio, setTrio] = useLocalStorage("trio", "127.0.0.1");
  const [vizEngine, setVizEngine] = useLocalStorage("viz_engine", "127.0.0.1");
  const [trioShow, setTrioShow] = useLocalStorage("trio_show", "PFL");
  const [trioShow2, setTrioShow2] = useLocalStorage("trio_show2", "weigh in"); //DAZN
  const [trioShow3, setTrioShow3] = useLocalStorage(
    "trio_show3",
    "PFL 2 WORLD FEED"
  ); //
  const [trioShow4, setTrioShow4] = useLocalStorage("trio_show4", "PFL 1XBET");
  const [vizProfile, setVizProfile] = useLocalStorage("viz_profile", "default");
  const [hyperClock, setHyperClock] = useLocalStorage(
    "hyperClock",
    (window.location.protocol.indexOf("https") > -1 ? "wss" : "ws") +
      "://clk1.hyper.live"
  );
  const [showID, setShowID] = useState();
  function setShow_ID(value) {
    show_ID = value;
    setShowID(value);
  }

  const [showID2, setShowID2] = useState();
  function setShow_ID2(value) {
    debugger;
    show_ID2 = value;
    setShowID2(value);
  }

  const [showID3, setShowID3] = useState();
  function setShow_ID3(value) {
    show_ID3 = value;
    setShowID3(value);
  }
  const [showID4, setShowID4] = useState();
  function setShow_ID4(value) {
    show_ID4 = value;
    setShowID4(value);
  }

  const animateOff = useCallback(
    (name, data) => {
      animateOffLocal(name, data);
    },
    [scenesOnAir, setScenesOnAir, ws, sendData]
  );

  const animate = useCallback(
    (name, data) => {
      animateLocal(name, data);
    },
    [scenesOnAir, setScenesOnAir, ws, sendData]
  );

  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("Rugby Control connecting to server");
    if (window?.ENV?.REACT_APP_VIZ || process.env.REACT_APP_VIZ) {
      window.control_ws = new WebSocket(hyperLink);
    } else {
      window.control_ws = new WebSocket(window.command_server);
    }
    setWS(window.control_ws);
  }

  useEffect(() => {
    if (window.control_ws) {
      let timeout;
      window.control_ws.onopen = () => {
        if (window?.ENV?.REACT_APP_VIZ || process.env.REACT_APP_VIZ) {
          getShow();
          getShow2();
          getShow3();
          getShow4();
        }
        setWSStatus(1);
        console.log("PFL Control on open");
        clearInterval(window.keep_alive);
        window.keep_alive = setInterval(() => {
          window.control_ws.send(
            JSON.stringify({
              keep_alive: Date.now(),
              group: window.id,
            })
          );
        }, 20000);
        sendData({
          group: window.id,
        });
      };
      window.control_ws.onmessage = (data) => {
        console.log("PFL Control on message");
        try {
          let obj = JSON.parse(data.data);

          if (obj) {
            if (obj.command_id && !obj.from_control) {
              let command = commands.find(
                (command) => command.id === obj.command_id
              );

              let xml = parser.parse(obj?.response, options);
              clearTimeout(command.timeout);
              command?.resolve(xml);
              commands = commands.filter(
                (command) => command.id !== obj.command_id
              );
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
      window.control_ws.onerror = (err) => {
        console.log("PFL Control on message", err);
        ws.close();
      };
      window.control_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("PFL Control on close");
        clearTimeout(timeout);
        clearInterval(window.keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function sendData(data) {
    try {
      if (window.control_ws) {
        data.user = {
          id: window.id,
        };

        data.group = localStorage.getItem("user-id");

        data.app = "PFL";
        window.control_ws.send(JSON.stringify({ ...data, from_control: true }));
      }
    } catch (err) {}
  }

  function updateGlobals(theme) {
    sendData({
      action: "globals",
      group: window.id,
      data: {
        globals: theme.data,
      },
    });
  }

  function preview(name, frame, data, timline) {
    sendData({
      action: "preview",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        frame: frame,
        timeline: timline || "IN",
        data: data,
      },
    });
  }

  function previewTrio(command) {
    sendData({
      action: "preview",
      trio: trio?.split(":")?.[0],
      port: trio?.split(":")?.[1],
      command,
      group: window.id,
      engine: window.engines?.[0]?.key,
    });
  }

  function vizSharedMemory({ shared_memory }) {
    // sendData({
    //   action: "preview",
    //   trio: trio?.split(":")?.[0],
    //   port: trio?.split(":")?.[1],
    //   shared_memory,
    //   group: window.id,
    //   engine: window.engines?.[0]?.key,
    // });
    sendData({
      action: "preview",
      trio: vizEngine?.split(":")?.[0],
      port: vizEngine?.split(":")?.[1],
      shared_memory,
      group: window.id,
      engine: window.engines?.[0]?.key,
    });
  }

  function vizCommand({ viz_command }) {
    // sendData({
    //   action: "preview",
    //   trio: trio?.split(":")?.[0],
    //   port: trio?.split(":")?.[1],
    //   viz_command,
    //   group: window.id,
    //   engine: window.engines?.[0]?.key,
    // });
    sendData({
      action: "preview",
      trio: vizEngine?.split(":")?.[0],
      port: vizEngine?.split(":")?.[1],
      viz_command,
      group: window.id,
      engine: window.engines?.[0]?.key,
    });
  }

  async function MSECommand({ url, options }) {
    let promise = new Promise((resolve, reject) => {
      let id = Math.random(Number.max);
      commands.push({
        id,
        resolve,
        reject,
        timeout: setTimeout(() => {
          reject();
        }, 5000),
      });

      sendData({
        command_id: id,
        mse: url,
        options,
        engine: window.engines?.[0]?.key,
        group: window.id,
      });
    });

    return promise;
  }

  const getShow = useCallback(async () => {
    return MSECommand({
      url: "http://" + mse + ":8580/directory/shows/",
    }).then((data) => {
      let show_obj = data?.feed?.entry?.find((show) => show.title === trioShow);
      let show_id = show_obj?.link?.[0]?.attr?.href?.split("/")?.slice(-2)?.[0];

      if (show_id) {
        console.log("got show id", show_id);
        setShow_ID(show_id);
      } else {
        setShow_ID();
      }
    });
  }, [mse]);

  const getShow2 = useCallback(async () => {
    return MSECommand({
      url: "http://" + mse + ":8580/directory/shows/",
    }).then((data) => {
      let show_obj = data?.feed?.entry?.find(
        (show) => show.title === trioShow2
      );
      let show_id2 = show_obj?.link?.[0]?.attr?.href
        ?.split("/")
        ?.slice(-2)?.[0];

      if (show_id2) {
        console.log("got show id 2", show_id2);
        setShow_ID2(show_id2);
      } else {
        setShow_ID2();
      }
    });
  }, [mse]);

  const getShow3 = useCallback(async () => {
    return MSECommand({
      url: "http://" + mse + ":8580/directory/shows/",
    }).then((data) => {
      let show_obj = data?.feed?.entry?.find(
        (show) => show.title === trioShow3
      );
      let show_id3 = show_obj?.link?.[0]?.attr?.href
        ?.split("/")
        ?.slice(-2)?.[0];

      if (show_id3) {
        console.log("got show id 3", show_id3);
        setShow_ID3(show_id3);
      } else {
        setShow_ID3();
      }
    });
  }, [mse]);

  const getShow4 = useCallback(async () => {
    return MSECommand({
      url: "http://" + mse + ":8580/directory/shows/",
    }).then((data) => {
      let show_obj = data?.feed?.entry?.find(
        (show) => show.title === trioShow4
      );
      let show_id4 = show_obj?.link?.[0]?.attr?.href
        ?.split("/")
        ?.slice(-2)?.[0];

      if (show_id4) {
        console.log("got show id 4", show_id4);
        setShow_ID4(show_id4);
      } else {
        setShow_ID4();
      }
    });
  }, [mse]);

  const getVizPage = useCallback(async ({ page, show = 1 }) => {
    if (!show_ID) {
      getShow();
    }
    if (!show_ID2) {
      getShow2();
    }
    if (!show_ID3) {
      getShow3();
    }
    if (!show_ID4) {
      getShow4();
    }
    let show_id = show_ID;
    if (show === 2) {
      show_id = show_ID2;
    } else if (show === 3) {
      show_id = show_ID3;
    } else if (show === 4) {
      show_id = show_ID4;
    }
    return MSECommand({
      url:
        "http://" +
        mse +
        ":8580/element_collection/storage/shows/" +
        show_id +
        "/elements/" +
        page +
        "/",
    });
  }, []);

  const updateVizPage = useCallback(
    async ({ page, data, escape = true, show = 1 }) => {
      if (!show_ID) {
        getShow();
      }
      if (!show_ID2) {
        getShow2();
      }
      if (!show_ID3) {
        getShow3();
      }
      if (!show_ID4) {
        getShow4();
      }
      let show_id = show_ID;
      if (show === 2) {
        show_id = show_ID2;
      } else if (show === 3) {
        show_id = show_ID3;
      } else if (show === 4) {
        show_id = show_ID4;
      }
      let xml = toXML.parse(data);
      if (escape) {
        xml = xml.replace(/&/g, "&amp;");
      }
      return MSECommand({
        url:
          "http://" +
          mse +
          ":8580/element_collection/storage/shows/" +
          show_id +
          "/elements/" +
          page +
          "/",
        options: {
          method: "PUT",
          headers: {
            "content-type": "application/vnd.vizrt.payload+xml;type=element",
          },
          body: xml,
        },
      });
    },
    []
  );

  const takeVizPage = useCallback(async ({ page }) => {
    if (!show_ID) {
      getShow();
    }
    if (!show_ID2) {
      getShow2();
    }
    if (!show_ID3) {
      getShow3();
    }
    if (!show_ID4) {
      getShow4();
    }
    let show_id = show_ID;
    return MSECommand({
      url: "http://" + mse + ":8580/profiles/" + vizProfile + "/take",
      options: {
        method: "POST",
        headers: {
          "content-type": "text/plain",
        },
        body:
          "http://" +
          mse +
          ":8580/element/storage/shows/" +
          show_id +
          "/elements/" +
          page,
      },
    });
  }, []);

  const takeOutVizPage = useCallback(async ({ page }) => {
    if (!show_ID) {
      getShow();
    }
    if (!show_ID2) {
      getShow2();
    }
    if (!show_ID3) {
      getShow3();
    }
    if (!show_ID4) {
      getShow4();
    }
    let show_id = show_ID;
    return MSECommand({
      url: "http://" + mse + ":8580/profiles/" + vizProfile + "/continue",
      options: {
        method: "POST",
        headers: {
          "content-type": "text/plain",
        },
        body:
          "http://" +
          mse +
          ":8580/element/storage/shows/" +
          show_id +
          "/elements/" +
          page,
      },
    });
  }, []);

  function clearPreview(name) {
    sendData({
      action: "CLEAR_PREVIEW",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
      },
    });
  }

  function clear(group) {
    setScenesOnAir([]);
    sendData({
      action: "CLEAR",
      group: "",
      engine: window.engines?.[0]?.key,
    });
  }

  function animateLocal(name, data, pageNumber) {
    clearPreview(name);

    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: "IN",
        data: data,
      },
    });
  }

  function update(name, data) {
    debugger;
    sendData({
      action: "update",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        data: data,
      },
    });
  }

  function triggerAnimation(name, data, animation) {
    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: animation,
        data: updateData(data),
      },
    });
  }

  function animateOffLocal(name, data) {
    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: "OUT",
        data: [],
      },
    });
  }

  function updateData(data) {
    if (data && data.length > 0) {
      return data.map((item) => {
        if (item.type === "TEXT") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              text: item.text,
              colour: item.colour,
              fontSize: item.fontSize || item.style._fontSize,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        } else if (item.type === "IMAGE") {
          if (item.name === "FG_IMAGE") {
            return {
              name: item.name,
              value: {
                visible: item.visible,
                image: item.image || item.src,
                width: item.width,
                height: item.height,
              },
            };
          } else {
            return {
              name: item.name,
              value: {
                visible: item.visible,
                image: item.image || item.src,
                position: {
                  x: item.x,
                  y: item.y,
                },
                width: item.width,
                height: item.height,
              },
            };
          }
        } else if (item.type === "GROUP") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        }
      });
    }
    return [];
  }

  function connectEngine(engine) {
    sendData({
      type: engine.status ? "connect-engine" : "disconnect-engine",
      engine: engine.name,
    });
  }

  return (
    <ControlContext.Provider
      value={{
        sendData,
        preview,
        animate,
        animateOff,
        scenesOnAir,
        clearPreview,
        setThemeName,
        wsStatus,
        clear,
        triggerAnimation,
        update,
        connectEngine,
        usersOnAir,
        previewTrio,
        MSECommand,
        getVizPage,
        updateVizPage,
        takeVizPage,
        takeOutVizPage,
        vizSharedMemory,
        showID,
        hyperLink,
        setHyperLink,
        mse,
        setMSE,
        trio,
        setTrio,
        setVizEngine,
        vizEngine,
        trioShow,
        setTrioShow,
        vizProfile,
        setVizProfile,
        hyperClock,
        setHyperClock,
        vizCommand,
      }}
    >
      {props.children}
    </ControlContext.Provider>
  );
};

export { ControlContext, ControlProvider };
