import React, { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import { DataContext } from "../../contexts/DataContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  height: 100px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
`;

const Button = styled.div`
  display: flex;
  background-color: #202731;

  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 1em;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;

export default function ClockEdit({ game }) {
  const { updateGame } = useContext(APIContext);
  const { updateGame: updateGameData, update } = useContext(DataContext);
  const { updateGraphic } = useContext(GraphicsContext);
  const history = useHistory();
  const { view, setView } = useContext(UIContext);
  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  return (
    <Main>
      <ActionPanel>
        {new Array(game?.number_of_rounds)?.fill(1)?.map((round, index) => {
          return (
            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                newData.live_data.periods = [
                  { kick_off: null, number: index + 1 },
                ];
                updateGraphic("CLOCK", {
                  game: game,
                  period: { kick_off: null, number: index + 1 },
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              Reset Round {index + 1}
            </Button>
          );
        })}
      </ActionPanel>

      {period?.kick_off && (
        <ActionPanel>
          <Button
            onClick={() => {
              let newData = { ...JSON.parse(JSON.stringify(game)) };
              let index = newData.live_data.periods.findIndex(
                (p) => p.number === period.number
              );
              let latestPause =
                newData.live_data.periods[index].pause &&
                newData.live_data.periods[index].pause[
                  newData.live_data.periods[index].pause.length - 1
                ];
              if (latestPause && latestPause.end) {
                latestPause = null;
              }

              if (latestPause) {
                latestPause.start -= 60000;
                updateGraphic("CLOCK", {
                  type: "PAUSE",
                  game: newData,
                  period: newData.live_data.periods[index],
                });
              } else {
                newData.live_data.periods[index].kick_off += 60000;
                updateGraphic("CLOCK", {
                  type: "RESUME",
                  game: newData,
                  period: newData.live_data.periods[index],
                });
              }
              updateGame({ id: game._id, live_data: newData.live_data });
            }}
          >
            +1 Min
          </Button>

          <Button
            onClick={() => {
              let newData = { ...JSON.parse(JSON.stringify(game)) };
              let index = newData.live_data.periods.findIndex(
                (p) => p.number === period.number
              );
              let latestPause =
                newData.live_data.periods[index].pause &&
                newData.live_data.periods[index].pause[
                  newData.live_data.periods[index].pause.length - 1
                ];
              if (latestPause && latestPause.end) {
                latestPause = null;
              }

              if (latestPause) {
                latestPause.start += 60000;
                updateGraphic("CLOCK", {
                  type: "PAUSE",
                  game: newData,
                  period: newData.live_data.periods[index],
                });
              } else {
                newData.live_data.periods[index].kick_off -= 60000;
                updateGraphic("CLOCK", {
                  type: "RESUME",
                  game: newData,
                  period: newData.live_data.periods[index],
                });
              }
              updateGame({ id: game._id, live_data: newData.live_data });
            }}
          >
            -1 Min
          </Button>

          <Button
            onClick={() => {
              let newData = { ...JSON.parse(JSON.stringify(game)) };
              let index = newData.live_data.periods.findIndex(
                (p) => p.number === period.number
              );

              let latestPause =
                newData.live_data.periods[index].pause &&
                newData.live_data.periods[index].pause[
                  newData.live_data.periods[index].pause.length - 1
                ];
              if (latestPause && latestPause.end) {
                latestPause = null;
              }

              if (latestPause) {
                latestPause.start -= 1000;
                updateGraphic("CLOCK", {
                  type: "PAUSE",
                  game: newData,
                  period: newData.live_data.periods[index],
                });
              } else {
                newData.live_data.periods[index].kick_off += 1000;
                updateGraphic("CLOCK", {
                  type: "RESUME",
                  game: newData,
                  period: newData.live_data.periods[index],
                });
              }

              updateGame({ id: game._id, live_data: newData.live_data });
            }}
          >
            +1 Sec
          </Button>
          <Button
            onClick={() => {
              let newData = { ...JSON.parse(JSON.stringify(game)) };
              let index = newData.live_data.periods.findIndex(
                (p) => p.number === period.number
              );

              let latestPause =
                newData.live_data.periods[index].pause &&
                newData.live_data.periods[index].pause[
                  newData.live_data.periods[index].pause.length - 1
                ];
              if (latestPause && latestPause.end) {
                latestPause = null;
              }

              if (latestPause) {
                latestPause.start += 1000;
                updateGraphic("CLOCK", {
                  type: "PAUSE",
                  game: newData,
                  period: newData.live_data.periods[index],
                });
              } else {
                newData.live_data.periods[index].kick_off -= 1000;
                updateGraphic("CLOCK", {
                  type: "RESUME",
                  game: newData,
                  period: newData.live_data.periods[index],
                });
              }

              updateGame({ id: game._id, live_data: newData.live_data });
            }}
          >
            -1 Sec
          </Button>
        </ActionPanel>
      )}
    </Main>
  );
}
