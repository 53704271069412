import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import moment from "moment";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { DataContext } from "../../contexts/DataContext";
import { FiPlay } from "react-icons/fi";
import { AiOutlinePause } from "react-icons/ai";
import { Button } from "../../Theme/Hyper";
import { APIContext } from "../../contexts/APIContext";
import { ClockContext } from "../../contexts/ClockContext";
import { useHistory, useLocation } from "react-router";
import { ControlContext } from "../../contexts/ControlContext";

const Main = styled.div`
  display: flex;
  font-weight: 100;
  box-shadow: 0px 5px 3px 0 rgb(0 0 0 / 12%);
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  z-index: 0;
  border-bottom: 1px solid #3d485d;
`;
const ClockSection = styled.div`
  display: flex;
  min-width: 70px;
  height: 90px;
  justify-content: ${(props) => (props.mins ? "flex-end" : "flex-start")};
`;
const ClockSplit = styled.div`
  height: 90px;
`;
const Half = styled.div`
  display: flex;
  font-size: 1.5em;
`;

const ClockContainer = styled.div`
  display: flex;
  font-size: 4em;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
`;
const ClockButton = styled.div`
  font-size: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 100%;
`;
const AddedTime = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
`;
const AddedTimeButton = styled.div`
  font-size: 1em;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#181d25")};

  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 25%;
  box-sizing: border-box;
  :hover {
    background-color: #db0a41;
  }
`;
const PauseResumeButton = styled(Button)`
  font-size: 0.3em;
  padding: 0.5em;
  font-weight: 100;
  margin-left: 0.5em;
  margin-right: 0.5em;
  height: 30px;
`;
const ClockDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
const ClockTypeButton = styled.div`
  font-size: 14px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#181d25")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-left: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;
let stadium_clock = "";
export default function Clock({ game }) {
  const history = useHistory();
  const location = useLocation();
  const { auto_clock, clockType, setClockType, setPeriod } =
    useContext(ClockContext);
  const [clock, setClock] = useState();
  const [minutes, setMinutes] = useState();
  const [countDown, setCountDown] = useState(true);

  let period = useMemo(() => {
    let p = [...(game?.live_data?.periods || [])].sort(
      (a, b) => b.number - a.number
    )[0];
    setPeriod(p);
    return p;
  }, [game]);

  const updateClock = useCallback(
    (koTime, period) => {
      let round_minutes =
        game?.round_minutes !== undefined && game?.round_minutes !== null
          ? game?.round_minutes
          : 5;
      let clockTime = round_minutes?.toString()?.padStart(2, "0") + ":00";

      if (koTime) {
        let pauseTime = 0;

        period.pause &&
          period.pause.forEach((pause) => {
            if (pause.end) {
              pauseTime += pause.end - pause.start;
            }
          });
        let latestPause = period.pause && period.pause[period.pause.length - 1];
        if (latestPause && latestPause.end) {
          latestPause = null;
        }
        let time = moment.duration(
          ((latestPause && latestPause.start) || Date.now()) -
            koTime -
            pauseTime
        );

        let count_down = moment.duration(
          Math.max(0, 1000 * 60 * round_minutes - time.asMilliseconds())
        );
        let minutes = count_down.minutes() + count_down.hours() * 60;

        setMinutes(minutes);

        clockTime =
          minutes.toString().padStart(2, "0") +
          ":" +
          count_down.seconds().toString().padStart(2, "0");

        setClock(clockTime);
      } else if (period.number) {
        let minutes = 0;
        let cd_mins = round_minutes;

        setMinutes(minutes);
        if (countDown) {
          clockTime = (cd_mins - minutes).toString().padStart(2, "0") + ":00";
        } else {
          clockTime = minutes.toString().padStart(2, "0") + ":00";
        }

        setClock(clockTime);
      }
    },
    [game]
  );

  useEffect(() => {
    if (game) {
      let period = [...(game?.live_data?.periods || [])].sort(
        (a, b) => b.number - a.number
      )[0];

      if (period) {
        updateClock(period.kick_off, period);
      }
      let interval = setInterval(() => {
        if (period) {
          updateClock(period.kick_off, period);
        }
      }, 500);
      return () => {
        clearInterval(interval);
      };
    } else {
      updateClock(0);
    }
  }, [game, updateClock]);

  function getHalfText(number) {
    if (number === 1) {
      return "Round 1";
    } else if (number === 2) {
      return "Round 2";
    } else if (number === 3) {
      return "Round 3";
    } else if (number === 4) {
      return "Round 4";
    }
    return number;
  }

  return (
    <Main>
      {game && period && (
        <Half>
          {getHalfText(period.number)} of {game?.number_of_round || 3}
        </Half>
      )}

      {clock && (
        <ClockContainer>
          {clockType === "manual" && (
            <Row>
              <ClockDiv
                onClick={() => {
                  if (location.pathname.indexOf("clock") > -1) {
                    history.push(location.pathname.replace("/clock", ""));
                  } else {
                    history.push("/" + game._id + "/clock");
                  }
                }}
              >
                <ClockSection mins>{clock.split(":")[0]}</ClockSection>
                <ClockSplit>:</ClockSplit>
                <ClockSection>{clock.split(":")[1]}</ClockSection>
              </ClockDiv>
            </Row>
          )}
        </ClockContainer>
      )}
    </Main>
  );
}
