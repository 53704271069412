import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/client";
import styled from "styled-components";
import CalendarUI from "../UI/Calendar/CalendarUI";
import moment from "moment";
import { ScrollBar } from "../../Theme/Hyper";
const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      date
      competition {
        _id
        oval_ID
        name
        competition_type
        badge
        secondary_badge
      }
      home_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
        nationality {
          code
        }
      }
      away_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
        nationality {
          code
        }
      }
      home_player_stats
      away_player_stats
      number_of_rounds
      gold_fight
      title
      championship_bar
      round_minutes
    }
  }
`;

const GAMES_BY_MONTH = gql`
  query Games($date: String) {
    gamesByMonth(date: $date) {
      _id
      date
    }
  }
`;
const Main = styled.div`
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
`;
const GamesDiv = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow-y: auto;
  height: 95vh;
`;
const Game = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.sub ? " #181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const Team = styled.div`
  display: flex;
  margin: 0.5em;
  width: 45%;
  justify-content: ${(props) => (props.right ? "flex-end" : "")};
`;
const Verses = styled.div`
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Competitions = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #202731;
  margin-left: 1px;
  width: ${(props) => (props.iPad ? "500px" : "300px")};
  padding: 1em;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;
const CATEGORIES = [
  "Landed Arm Strikes",
  "Landed Leg Strikes",
  "Landed Ground Strikes",
  "Takedown Success",
  "Submission Success",
  "Missed Arm Strikes",
  "Missed Leg Strikes",
  "Missed Ground Strikes",
  "Takedown Attempted",
  "Submission Attempted",
  "Knockdowns",
];

function Data() {
  const [getFixtures, { data }] = useLazyQuery(GAMES_BY_DATE, {
    fetchPolicy: "network-only",
  });
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [fixtures, setFixtures] = useState([]);
  const [monthFixtures, setMonthFixtures] = useState();
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [getMonthFixtures, { data: month_data }] = useLazyQuery(
    GAMES_BY_MONTH,
    {
      fetchPolicy: "network-only",
    }
  );

  function calTotal({ stat_1, stat_2, stats, round }) {
    return (
      stats
        ?.filter((r) => r.round === round || !round)
        ?.reduce((prev, curr) => {
          return prev + (curr?.[stat_1] || 0) + (curr?.[stat_2] || 0);
        }, 0) || 0
    );
  }
  function calcTotalStrikesThrown({ stats, round }) {
    return (
      stats
        ?.filter((r) => r.round === round || !round)
        ?.reduce((prev, curr) => {
          return (
            prev +
            (curr?.[CATEGORIES[0]] || 0) +
            (curr?.[CATEGORIES[1]] || 0) +
            (curr?.[CATEGORIES[2]] || 0) +
            (curr?.[CATEGORIES[5]] || 0) +
            (curr?.[CATEGORIES[6]] || 0) +
            (curr?.[CATEGORIES[7]] || 0)
          );
        }, 0) || 0
    );
  }

  function calcTotalStrikesLanded({ stats, round }) {
    let total =
      stats
        ?.filter((r) => r.round === round || !round)
        ?.reduce((prev, curr) => {
          return (
            prev +
            (curr?.[CATEGORIES[0]] || 0) +
            (curr?.[CATEGORIES[1]] || 0) +
            (curr?.[CATEGORIES[2]] || 0)
          );
        }, 0) || 0;

    return total;
  }
  function calcTotalVariable({ variable, stats, round }) {
    return (
      stats
        ?.filter((r) => r.round === round || !round)
        ?.reduce((prev, curr) => {
          return prev + (curr?.[variable] || 0);
        }, 0) || 0
    );
  }
  useEffect(() => {
    if (data) {
      let obj = data.gamesByDate?.map((game) => {
        return {
          Id: game?._id,
          Fighters: [
            {
              Id: game?.home_fighter?._id,
              Corner: "Red",
              FirstName: game?.home_fighter?.first_name,
              LastName: game?.home_fighter?.last_name,
              FightStats: {
                StrikesLanded: calcTotalStrikesLanded({
                  stats: game?.home_player_stats,
                }),
                StrikesThrown: calcTotalStrikesThrown({
                  stats: game?.home_player_stats,
                }),
                ArmStrikesLanded: calcTotalVariable({
                  variable: CATEGORIES[0],
                  stats: game?.home_player_stats,
                  round: 1,
                }),
                ArmStrikesThrown: calTotal({
                  stat_1: CATEGORIES[0],
                  stat_2: CATEGORIES[5],
                  stats: game?.home_player_stats,
                }),
                LegStrikesLanded: calcTotalVariable({
                  variable: CATEGORIES[1],
                  stats: game?.home_player_stats,
                  round: 1,
                }),
                LegStrikesThrown: calTotal({
                  stat_1: CATEGORIES[1],
                  stat_2: CATEGORIES[6],
                  stats: game?.home_player_stats,
                }),
                GroundStrikesLanded: calcTotalVariable({
                  variable: CATEGORIES[2],
                  stats: game?.home_player_stats,
                  round: 1,
                }),
                GroundStrikesThrown: calTotal({
                  stat_1: CATEGORIES[2],
                  stat_2: CATEGORIES[7],
                  stats: game?.home_player_stats,
                }),
                Takedowns: calTotal({
                  variable: CATEGORIES[3],
                  stats: game?.home_player_stats,
                }),
                TakedownAttempts: calTotal({
                  variable: CATEGORIES[8],
                  stats: game?.home_player_stats,
                }),
                SubmissionAttempts: calTotal({
                  variable: CATEGORIES[9],
                  stats: game?.home_player_stats,
                }),
                Knockdowns: calTotal({
                  variable: CATEGORIES[10],
                  stats: game?.home_player_stats,
                }),
              },
              RoundStats: [
                {
                  Round: 1,
                  StrikesLanded: calcTotalStrikesLanded({
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  StrikesThrown: calcTotalStrikesThrown({
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  ArmStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[0],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  ArmStrikesThrown: calTotal({
                    stat_1: CATEGORIES[0],
                    stat_2: CATEGORIES[5],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  LegStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[1],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  LegStrikesThrown: calTotal({
                    stat_1: CATEGORIES[1],
                    stat_2: CATEGORIES[6],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  GroundStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[2],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  GroundStrikesThrown: calTotal({
                    stat_1: CATEGORIES[2],
                    stat_2: CATEGORIES[7],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  Takedowns: calcTotalVariable({
                    variable: CATEGORIES[3],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  TakedownAttempts: calcTotalVariable({
                    variable: CATEGORIES[8],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  SubmissionAttempts: calcTotalVariable({
                    variable: CATEGORIES[9],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                  Knockdowns: calcTotalVariable({
                    variable: CATEGORIES[10],
                    stats: game?.home_player_stats,
                    round: 1,
                  }),
                },
                {
                  Round: 2,
                  StrikesLanded: calcTotalStrikesLanded({
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  StrikesThrown: calcTotalStrikesThrown({
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  ArmStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[0],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  ArmStrikesThrown: calTotal({
                    stat_1: CATEGORIES[0],
                    stat_2: CATEGORIES[5],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  LegStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[1],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  LegStrikesThrown: calTotal({
                    stat_1: CATEGORIES[1],
                    stat_2: CATEGORIES[6],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  GroundStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[2],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  GroundStrikesThrown: calTotal({
                    stat_1: CATEGORIES[2],
                    stat_2: CATEGORIES[7],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  Takedowns: calcTotalVariable({
                    variable: CATEGORIES[3],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  TakedownAttempts: calcTotalVariable({
                    variable: CATEGORIES[8],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  SubmissionAttempts: calcTotalVariable({
                    variable: CATEGORIES[9],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                  Knockdowns: calcTotalVariable({
                    variable: CATEGORIES[10],
                    stats: game?.home_player_stats,
                    round: 2,
                  }),
                },
                {
                  Round: 3,
                  StrikesLanded: calcTotalStrikesLanded({
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  StrikesThrown: calcTotalStrikesThrown({
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  ArmStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[0],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  ArmStrikesThrown: calTotal({
                    stat_1: CATEGORIES[0],
                    stat_2: CATEGORIES[5],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  LegStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[1],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  LegStrikesThrown: calTotal({
                    stat_1: CATEGORIES[1],
                    stat_2: CATEGORIES[6],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  GroundStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[2],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  GroundStrikesThrown: calTotal({
                    stat_1: CATEGORIES[2],
                    stat_2: CATEGORIES[7],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  Takedowns: calcTotalVariable({
                    variable: CATEGORIES[3],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  TakedownAttempts: calcTotalVariable({
                    variable: CATEGORIES[8],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  SubmissionAttempts: calcTotalVariable({
                    variable: CATEGORIES[9],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                  Knockdowns: calcTotalVariable({
                    variable: CATEGORIES[10],
                    stats: game?.home_player_stats,
                    round: 3,
                  }),
                },
              ],
            },
            {
              Id: game?.away_fighter?._id,
              Corner: "Blue",
              FirstName: game?.away_fighter?.first_name,
              LastName: game?.away_fighter?.last_name,
              FightStats: {
                StrikesLanded: calcTotalStrikesLanded({
                  stats: game?.away_player_stats,
                }),
                StrikesThrown: calcTotalStrikesThrown({
                  stats: game?.away_player_stats,
                }),
                ArmStrikesLanded: calcTotalVariable({
                  variable: CATEGORIES[0],
                  stats: game?.away_player_stats,
                  round: 1,
                }),
                ArmStrikesThrown: calTotal({
                  stat_1: CATEGORIES[0],
                  stat_2: CATEGORIES[5],
                  stats: game?.away_player_stats,
                }),
                LegStrikesLanded: calcTotalVariable({
                  variable: CATEGORIES[1],
                  stats: game?.away_player_stats,
                  round: 1,
                }),
                LegStrikesThrown: calTotal({
                  stat_1: CATEGORIES[1],
                  stat_2: CATEGORIES[6],
                  stats: game?.away_player_stats,
                }),
                GroundStrikesLanded: calcTotalVariable({
                  variable: CATEGORIES[2],
                  stats: game?.away_player_stats,
                  round: 1,
                }),
                GroundStrikesThrown: calTotal({
                  stat_1: CATEGORIES[2],
                  stat_2: CATEGORIES[7],
                  stats: game?.away_player_stats,
                }),
                Takedowns: calTotal({
                  variable: CATEGORIES[3],
                  stats: game?.away_player_stats,
                }),
                TakedownAttempts: calTotal({
                  variable: CATEGORIES[8],
                  stats: game?.away_player_stats,
                }),
                SubmissionAttempts: calTotal({
                  variable: CATEGORIES[9],
                  stats: game?.away_player_stats,
                }),
                Knockdowns: calTotal({
                  variable: CATEGORIES[10],
                  stats: game?.away_player_stats,
                }),
              },
              RoundStats: [
                {
                  Round: 1,
                  StrikesLanded: calcTotalStrikesLanded({
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  StrikesThrown: calcTotalStrikesThrown({
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  ArmStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[0],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  ArmStrikesThrown: calTotal({
                    stat_1: CATEGORIES[0],
                    stat_2: CATEGORIES[5],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  LegStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[1],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  LegStrikesThrown: calTotal({
                    stat_1: CATEGORIES[1],
                    stat_2: CATEGORIES[6],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  GroundStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[2],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  GroundStrikesThrown: calTotal({
                    stat_1: CATEGORIES[2],
                    stat_2: CATEGORIES[7],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  Takedowns: calcTotalVariable({
                    variable: CATEGORIES[3],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  TakedownAttempts: calcTotalVariable({
                    variable: CATEGORIES[8],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  SubmissionAttempts: calcTotalVariable({
                    variable: CATEGORIES[9],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                  Knockdowns: calcTotalVariable({
                    variable: CATEGORIES[10],
                    stats: game?.away_player_stats,
                    round: 1,
                  }),
                },
                {
                  Round: 2,
                  StrikesLanded: calcTotalStrikesLanded({
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  StrikesThrown: calcTotalStrikesThrown({
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  ArmStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[0],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  ArmStrikesThrown: calTotal({
                    stat_1: CATEGORIES[0],
                    stat_2: CATEGORIES[5],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  LegStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[1],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  LegStrikesThrown: calTotal({
                    stat_1: CATEGORIES[1],
                    stat_2: CATEGORIES[6],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  GroundStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[2],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  GroundStrikesThrown: calTotal({
                    stat_1: CATEGORIES[2],
                    stat_2: CATEGORIES[7],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  Takedowns: calcTotalVariable({
                    variable: CATEGORIES[3],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  TakedownAttempts: calcTotalVariable({
                    variable: CATEGORIES[8],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  SubmissionAttempts: calcTotalVariable({
                    variable: CATEGORIES[9],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                  Knockdowns: calcTotalVariable({
                    variable: CATEGORIES[10],
                    stats: game?.away_player_stats,
                    round: 2,
                  }),
                },
                {
                  Round: 3,
                  StrikesLanded: calcTotalStrikesLanded({
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  StrikesThrown: calcTotalStrikesThrown({
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  ArmStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[0],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  ArmStrikesThrown: calTotal({
                    stat_1: CATEGORIES[0],
                    stat_2: CATEGORIES[5],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  LegStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[1],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  LegStrikesThrown: calTotal({
                    stat_1: CATEGORIES[1],
                    stat_2: CATEGORIES[6],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  GroundStrikesLanded: calcTotalVariable({
                    variable: CATEGORIES[2],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  GroundStrikesThrown: calTotal({
                    stat_1: CATEGORIES[2],
                    stat_2: CATEGORIES[7],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  Takedowns: calcTotalVariable({
                    variable: CATEGORIES[3],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  TakedownAttempts: calcTotalVariable({
                    variable: CATEGORIES[8],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  SubmissionAttempts: calcTotalVariable({
                    variable: CATEGORIES[9],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                  Knockdowns: calcTotalVariable({
                    variable: CATEGORIES[10],
                    stats: game?.away_player_stats,
                    round: 3,
                  }),
                },
              ],
            },
          ],
        };
      });

      fetch("https://pfl-data.hyper.live/data", {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });
      setFixtures([...(data.gamesByDate || [])]);
    }
  }, [data]);

  useEffect(() => {
    if (date) {
      getFixtures({ variables: { date: date + "Z" } });
      let interval = setInterval(() => {
        getFixtures({ variables: { date: date + "Z" } });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [date, getFixtures]);

  useEffect(() => {
    if (selectedMonth) {
      getMonthData();
    }
  }, [selectedMonth, getMonthFixtures]);

  useEffect(() => {
    if (month_data) {
      setMonthFixtures([...(month_data?.gamesByMonth || [])]);
    }
  }, [month_data]);

  function getMonthData() {
    getMonthFixtures({
      variables: { date: selectedMonth.format("YYYY-MM-DD") + "Z" },
    });
  }

  return (
    <Main>
      <Competitions iPad={false}>
        <CalendarUI
          onChangeDate={(date) => {
            setDate(moment(date).format("YYYY-MM-DD"));
          }}
          date={moment()}
          onActiveStartDateChange={({ activeStartDate, value }) => {
            setSelectedMonth(moment(activeStartDate));
          }}
          highlights={monthFixtures?.map((m) => m.date)}
        />
      </Competitions>
      <Column>
        <GamesDiv>
          {fixtures
            ?.sort((a, b) => {
              if (a.competition > b.competition) {
                return 1;
              } else if (a.competition < b.competition) {
                return -1;
              }

              let a_date = moment(a.date + " " + a.time);
              let b_date = moment(b.date + " " + b.time);
              // Else go to the 2nd item
              if (a_date < b_date) {
                return -1;
              } else if (a_date > b_date) {
                return 1;
              } else {
                // nothing to split them
                return 0;
              }
            })
            ?.map((fixture, index) => {
              return (
                <>
                  {(index === 0 ||
                    fixtures[index - 1].competition._id !==
                      fixture.competition._id) && (
                    <div>{fixture.competition?.name}</div>
                  )}
                  <Game
                    onClick={() => {
                      //   onSelectGame(fixture);
                      //   let path = location.pathname;
                      //   history.push({
                      //     pathname:
                      //       location.pathname.replace("/", "") +
                      //       "/" +
                      //       fixture._id,
                      //   });
                    }}
                  >
                    <Team right>
                      {fixture.home_fighter?.first_name}{" "}
                      {fixture.home_fighter?.last_name}
                    </Team>
                    <Verses>
                      <div>
                        Fight {index + 1} -{" "}
                        {moment("01/01/1990 " + fixture.time).format("HH:mm")}
                      </div>
                    </Verses>
                    <Team>
                      {fixture.away_fighter?.first_name}{" "}
                      {fixture.away_fighter?.last_name}
                    </Team>
                  </Game>
                </>
              );
            })}
        </GamesDiv>
      </Column>
    </Main>
  );
}

export default Data;
