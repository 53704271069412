import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import CIQLOGO from "../../Images/combat.64b31da3.svg";
const CATEGORIES = [
  "Landed Arm Strikes",
  "Landed Leg Strikes",
  "Landed Ground Strikes",
  "Takedown Success",
  "Submission Success",
  "Missed Arm Strikes",
  "Missed Leg Strikes",
  "Missed Ground Strikes",
  "Takedown Attempted",
  "Submission Attempted",
  "Knockdowns",
];

const CATEGORIES_CIQ = ["TOT", "ARM", "LEG", "GD", "TD", "KD", "SUB"];

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  color: white;
  th {
    padding: 10px;
    text-align: center;
  }
  td {
    padding: 15px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 150px;
    border-bottom: 1px solid black;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  text-align: left;
`;

const TR = styled.tr`
  background-color: ${(props) =>
    props.title ? "#343e4e" : props.odd ? "#0f131a" : ""};
  > td:nth-child(2),
  > td:nth-child(4),
  > td:nth-child(6) {
    background-color: ${(props) => (!props.title ? "#932c2c" : "")};
  }
  > td:nth-child(3),
  > td:nth-child(5),
  > td:nth-child(7) {
    background-color: ${(props) => (!props.title ? "#222274" : "")};
  }
`;
export default function TeamStatsCIQ({ game }) {
  let period = useMemo(() => {
    let p = { number: game?.ciq_stats?.Curr_round };

    return p;
  }, [game]);

  function calcTotalStrikesThrown({ stats, round }) {
    return (
      stats
        ?.filter((r) => r?.round === round || !round)
        ?.reduce((prev, curr) => {
          return prev + (curr?.[CATEGORIES_CIQ[0]]?.Att || 0);
        }, 0) || 0
    );
  }

  function calcTotalStrikesLanded({ stats, round }) {
    let total_overall =
      stats
        ?.filter((r) => r?.round === round || !round)
        ?.reduce((prev, curr) => {
          return prev + (curr?.[CATEGORIES_CIQ[0]]?.Att || 0);
        }, 0) || 0;
    let total =
      stats
        ?.filter((r) => r?.round === round || !round)
        ?.reduce((prev, curr) => {
          return prev + (curr?.[CATEGORIES_CIQ[0]]?.Succ || 0);
        }, 0) || 0;

    return (
      total + " (" + (Math.round((total / total_overall) * 100) || 0) + "%)"
    );
  }

  function calTotal({ variable, stats, round }) {
    return (
      stats
        ?.filter((r) => r?.round === round || !round)
        ?.reduce((prev, curr) => {
          var value = variable?.split(".")?.reduce((a, b) => a?.[b], curr);

          return prev + (value || 0);
        }, 0) || 0
    );
  }

  function calPercentage({ missed, landed, stats, round }) {
    let missed_total = stats
      ?.filter((r) => r?.round === round || !round)
      ?.reduce((prev, curr) => {
        var value = missed?.split(".").reduce((a, b) => a?.[b], curr);

        return prev + (value || 0);
      }, 0);
    let landed_total = stats
      ?.filter((r) => r?.round === round || !round)
      ?.reduce((prev, curr) => {
        var value = landed?.split(".").reduce((a, b) => a?.[b], curr);
        return prev + (value || 0);
      }, 0);
    let output = (landed_total || 0) + " (";

    output +=
      Math.round(
        ((landed_total || 0) / ((missed_total || 0) + (landed_total || 0))) *
          100
      ) || 0;
    output += "%)";
    return output;
  }

  return (
    game && (
      <Main>
        <Table>
          <thead>
            <tr>
              <TH>
                <img src={CIQLOGO} alt="combat IQ logo" />
              </TH>
              <TH colSpan={2}>TOTAL FIGHT</TH>
              <TH colSpan={2}>CURRENT ROUND</TH>
              <TH colSpan={2}>LAST ROUND</TH>
            </tr>
          </thead>
          <tbody>
            <TR title={true}>
              <td>TOTAL STRIKES</td>
              <td>{game?.home_fighter?.last_name}</td>
              <td>{game?.away_fighter?.last_name}</td>
              <td>{game?.home_fighter?.last_name}</td>
              <td>{game?.away_fighter?.last_name}</td>
              <td>{game?.home_fighter?.last_name}</td>
              <td>{game?.away_fighter?.last_name}</td>
            </TR>
            <TR>
              <td>THROWN</td>
              <td>
                {calcTotalStrikesThrown({ stats: game?.home_player_ciq_stats })}
              </td>
              <td>
                {calcTotalStrikesThrown({ stats: game?.away_player_ciq_stats })}
              </td>
              <td>
                {calcTotalStrikesThrown({
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {calcTotalStrikesThrown({
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {period?.number > 1 &&
                  calcTotalStrikesThrown({
                    stats: game?.home_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
              <td>
                {period?.number > 1 &&
                  calcTotalStrikesThrown({
                    stats: game?.away_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
            </TR>
            <TR odd={true}>
              <td>LANDED</td>
              <td>
                {calcTotalStrikesLanded({ stats: game?.home_player_ciq_stats })}
              </td>
              <td>
                {calcTotalStrikesLanded({ stats: game?.away_player_ciq_stats })}
              </td>
              <td>
                {calcTotalStrikesLanded({
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {calcTotalStrikesLanded({
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {period?.number > 1 &&
                  calcTotalStrikesLanded({
                    stats: game?.home_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
              <td>
                {period?.number > 1 &&
                  calcTotalStrikesLanded({
                    stats: game?.away_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
            </TR>
            <TR title={true}>
              <td>ARM STRIKES</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </TR>
            <TR>
              <td>THROWN</td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[1] + ".Att",
                  stats: game?.home_player_ciq_stats,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[1] + ".Att",
                  stats: game?.away_player_ciq_stats,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[1] + ".Att",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number || 1,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[1] + ".Att",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number || 1,
                })}
              </td>
              <td>
                {period?.number > 1 &&
                  calTotal({
                    variable: CATEGORIES_CIQ[1] + ".Att",
                    stats: game?.home_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
              <td>
                {period?.number > 1 &&
                  calTotal({
                    variable: CATEGORIES_CIQ[1] + ".Att",
                    stats: game?.away_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
            </TR>
            <TR odd={true}>
              <td>LANDED</td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[1] + ".Succ",
                  missed: CATEGORIES_CIQ[1] + ".Att",
                  stats: game?.home_player_ciq_stats,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[1] + ".Succ",
                  missed: CATEGORIES_CIQ[1] + ".Att",
                  stats: game?.away_player_ciq_stats,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[1] + ".Succ",
                  missed: CATEGORIES_CIQ[1] + ".Att",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[1] + ".Succ",
                  missed: CATEGORIES_CIQ[1] + ".Att",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {period?.number > 1 &&
                  calPercentage({
                    landed: CATEGORIES_CIQ[1] + ".Succ",
                    missed: CATEGORIES_CIQ[1] + ".Att",
                    stats: game?.home_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
              <td>
                {period?.number > 1 &&
                  calPercentage({
                    landed: CATEGORIES_CIQ[1] + ".Succ",
                    missed: CATEGORIES_CIQ[1] + ".Att",
                    stats: game?.away_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
            </TR>
            <TR title={true}>
              <td>LEG STRIKES</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </TR>
            <TR>
              <td>THROWN</td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[2] + ".Att",
                  stats: game?.home_player_ciq_stats,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[2] + ".Att",
                  stats: game?.away_player_ciq_stats,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[2] + ".Att",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[2] + ".Att",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {period?.number > 1 &&
                  calTotal({
                    variable: CATEGORIES_CIQ[2] + ".Att",
                    stats: game?.home_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
              <td>
                {period?.number > 1 &&
                  calTotal({
                    variable: CATEGORIES_CIQ[2] + ".Att",
                    stats: game?.away_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
            </TR>
            <TR odd={true}>
              <td>LANDED</td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[2] + ".Succ",
                  missed: CATEGORIES_CIQ[2] + ".Att",
                  stats: game?.home_player_ciq_stats,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[2] + ".Succ",
                  missed: CATEGORIES_CIQ[2] + ".Att",
                  stats: game?.away_player_ciq_stats,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[2] + ".Succ",
                  missed: CATEGORIES_CIQ[2] + ".Att",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[2] + ".Succ",
                  missed: CATEGORIES_CIQ[2] + ".Att",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {period?.number > 1 &&
                  calPercentage({
                    landed: CATEGORIES_CIQ[2] + ".Succ",
                    missed: CATEGORIES_CIQ[2] + ".Att",
                    stats: game?.home_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
              <td>
                {period?.number > 1 &&
                  calPercentage({
                    landed: CATEGORIES_CIQ[2] + ".Succ",
                    missed: CATEGORIES_CIQ[2] + ".Att",
                    stats: game?.away_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
            </TR>
            <TR title={true}>
              <td>GROUND STRIKES</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </TR>
            <TR>
              <td>THROWN</td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[3] + ".Att",
                  stats: game?.home_player_ciq_stats,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[3] + ".Att",
                  stats: game?.away_player_ciq_stats,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[3] + ".Att",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[3] + ".Att",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {period?.number > 1 &&
                  calTotal({
                    variable: CATEGORIES_CIQ[3] + ".Att",
                    stats: game?.home_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
              <td>
                {period?.number > 1 &&
                  calTotal({
                    variable: CATEGORIES_CIQ[3] + ".Att",
                    stats: game?.away_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
            </TR>
            <TR odd={true}>
              <td>LANDED</td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[3] + ".Succ",
                  missed: CATEGORIES_CIQ[3] + ".Att",
                  stats: game?.home_player_ciq_stats,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[3] + ".Succ",
                  missed: CATEGORIES_CIQ[3] + ".Att",
                  stats: game?.away_player_ciq_stats,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[3] + ".Succ",
                  missed: CATEGORIES_CIQ[3] + ".Att",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {calPercentage({
                  landed: CATEGORIES_CIQ[3] + ".Succ",
                  missed: CATEGORIES_CIQ[3] + ".Att",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}
              </td>
              <td>
                {period?.number > 1 &&
                  calPercentage({
                    landed: CATEGORIES_CIQ[3] + ".Succ",
                    missed: CATEGORIES_CIQ[3] + ".Att",
                    stats: game?.home_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
              <td>
                {period?.number > 1 &&
                  calPercentage({
                    landed: CATEGORIES_CIQ[3] + ".Succ",
                    missed: CATEGORIES_CIQ[3] + ".Att",
                    stats: game?.away_player_ciq_stats,
                    round: period?.number - 1,
                  })}
              </td>
            </TR>
            {/* <TR title={true}>
              <td>STRIKES ABSORBED</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </TR>
            <TR>
              <td>HEAD</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </TR>
            <TR odd={true}>
              <td>BODY</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </TR>
            <TR odd={true}>
              <td>LEG (Left / Right)</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </TR> */}
          </tbody>
        </Table>
      </Main>
    )
  );
}
