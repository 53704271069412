import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid #202731;
`;
const StatDiv = styled.div`
  display: flex;
`;
const Category = styled.div`
  display: flex;
  min-width: 50%;
  padding: 0.5em;
  font-size: 0.7em;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Value = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 2px;
  align-items: center;
  font-size: ${(props) => (props.small ? ".8em" : "")};
  :hover {
    background-color: #db0a41;
  }
`;

export default function SeasonStat({
  category,
  season_value,
  game_value,
  previewGraphic,
  game,
  player,
}) {
  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  return (
    <Main>
      <StatDiv>
        <Category
          onClick={() => {
            let cat = "";
            switch (category) {
              case "tries":
                cat = "try";
                break;
              case "goals":
                cat = "goal";
                break;
              default:
            }
            previewGraphic("PlayerSeasonStat", {
              player: player,
              subtext:
                ordinal_suffix_of(parseInt(season_value + game_value)) +
                " " +
                cat +
                " this season",
              team: player?.team,
              value: parseInt(season_value) + game_value,
              category,
              game,
            });
          }}
        >
          {category}
        </Category>
        <Value
          style={{ color: "#919191" }}
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {season_value}
        </Value>
        <Value
          style={{ color: "#919191" }}
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {game_value}
        </Value>
        <Value
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {parseInt(season_value || 0) + (game_value || 0)}
        </Value>
      </StatDiv>
    </Main>
  );
}
