import { Clock } from "./Clock";
import { Cagenomics } from "./Cagenomics";
import { TeamStat } from "./TeamStat";

export default function graphics({
  takeVizPage: animate,
  update,
  takeOutVizPage: animateOff,
  triggerAnimation,
  clearPreview,
  previewTrio: preview,
  getVizPage,
  updateVizPage,
  vizSharedMemory,
  vizCommand
}) {
  return [
    new Clock({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      vizCommand
    }),
    new Cagenomics({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new TeamStat({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
  ];
}
