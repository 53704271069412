import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Stat from "./Stat";
import ActionAreas from "./ActionAreas";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";
import { ClockContext } from "../../contexts/ClockContext";
import useLocalStorage from "../../Hooks/useLocalStorage";

const CATEGORIES = [
  "Landed Arm Strikes",
  "Landed Leg Strikes",
  "Landed Ground Strikes",
  "Takedown Success",
  "Submission Success",
  "Missed Arm Strikes",
  "Missed Leg Strikes",
  "Missed Ground Strikes",
  "Takedown Attempted",
  "Submission Attempted",
  "Knockdown Success",
];

const GFX_CATEGORIES = [
  {
    name: "Arm Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[5],
        landed: CATEGORIES[0],
        stats,
      });
    },
  },
  {
    name: "Leg Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[6],
        landed: CATEGORIES[1],
        stats,
      });
    },
  },
  {
    name: "Ground Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[7],
        landed: CATEGORIES[2],
        stats,
      });
    },
  },
  {
    name: "Knockdowns",
    stat: (stats) => {
      return (
        stats?.reduce((prev, curr) => {
          return prev + (curr?.[CATEGORIES[10]] || 0);
        }, 0) || 0
      );
    },
  },
  {
    name: "Takedowns",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[8],
        landed: CATEGORIES[3],
        stats,
      });
    },
  },
];

const calPercentage = function ({ missed, landed, stats, round }) {
  let missed_total = stats
    ?.filter((r) => r.round === round || !round)
    ?.reduce((prev, curr) => {
      return prev + (curr?.[missed] || 0);
    }, 0);
  let landed_total = stats
    ?.filter((r) => r.round === round || !round)
    ?.reduce((prev, curr) => {
      return prev + (curr?.[landed] || 0);
    }, 0);
  let output = (landed_total || 0) + "/";

  output += (missed_total || 0) + (landed_total || 0);

  return output;
};

const CATEGORIES_CIQ = ["TOT", "ARM", "LEG", "GD", "TD", "KD", "SUB"];

const GFX_CATEGORIES_CIQ = [
  {
    name: "Arm Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[1] + ".Succ",
        missed: CATEGORIES_CIQ[1] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Leg Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[2] + ".Succ",
        missed: CATEGORIES_CIQ[2] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Ground Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[3] + ".Succ",
        missed: CATEGORIES_CIQ[3] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Knockdowns",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[5] + ".Succ",
        missed: CATEGORIES_CIQ[5] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Takedowns",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[4] + ".Succ",
        missed: CATEGORIES_CIQ[4] + ".Att",
        stats: stats,
      });
    },
  },
];

function calPercentageCIQ({ missed, landed, stats, round }) {
  let missed_total = stats
    ?.filter((r) => r?.round === round || !round)
    ?.reduce((prev, curr) => {
      var value = missed?.split(".").reduce((a, b) => a?.[b], curr);

      return prev + (value || 0);
    }, 0);
  let landed_total = stats
    ?.filter((r) => r?.round === round || !round)
    ?.reduce((prev, curr) => {
      var value = landed?.split(".").reduce((a, b) => a?.[b], curr);
      return prev + (value || 0);
    }, 0);
  let output = (landed_total || 0) + "/";

  output += (missed_total || 0) + (landed_total || 0);

  return output;
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;
const Button = styled.div`
  padding: 0.5em;
  min-width: 50px;
  display: flex;
  background-color: ${(props) =>
    props.selected ? "#db0a41" : props.green ? "#0adb93" : "#202731"};
  color: ${(props) => (props.green ? "#000" : "#fff")};
  justify-content: center;
  align-items: center;
  height: 90%;
  border-radius: 4px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  box-sizing: border-box;
  :hover {
    background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
    cursor: pointer;
  }
`;
export default function TeamStats({ game }) {
  const { previewGraphic, updateGraphic } = useContext(GraphicsContext);

  const [showPhase, setShowPhase] = useState();

  const [statOne, setStatOne] = useLocalStorage("stat_one");
  const [statTwo, setStatTwo] = useLocalStorage("stat_two");
  const [statThree, setStatThree] = useLocalStorage("stat_three");
  const [statFour, setStatFour] = useLocalStorage("stat_four");
  const [selectedStat, setSelectedStat] = useState("");
  const [hl, setHL] = useLocalStorage("stat_hl", []);

  useEffect(() => {
    updateGraphics();
  }, [game, statOne, statTwo, statThree, statFour]);

  function updateGraphics() {
    for (let i = 1; i <= 4; i++) {
      let stat = "";
      switch (i) {
        case 1:
          stat = statOne;
          break;
        case 2:
          stat = statTwo;
          break;
        case 3:
          stat = statThree;
          break;
        case 4:
          stat = statFour;
          break;
        default:
      }
      if (stat?.indexOf("_CIQ") > -1) {
        previewGraphic("TeamStat", {
          variable: "B" + i + "_F1",
          value:
            GFX_CATEGORIES_CIQ?.find(
              (s) => s.name === stat?.replace("_CIQ", "")
            )?.stat(game?.home_player_stats) || "",
        });
        previewGraphic("TeamStat", {
          variable: "B" + i + "_F2",
          value:
            GFX_CATEGORIES?.find(
              (s) => s.name === stat?.replace("_CIQ", "")
            )?.stat(game?.away_player_stats) || "",
        });
        previewGraphic("TeamStat", {
          variable: "Box_" + i,
          value: stat?.replace("_CIQ", "")?.toUpperCase() || "",
        });
      } else {
        previewGraphic("TeamStat", {
          variable: "B" + i + "_F1",
          value:
            GFX_CATEGORIES?.find((s) => s.name === stat)?.stat(
              game?.home_player_stats
            ) || "0",
        });
        previewGraphic("TeamStat", {
          variable: "B" + i + "_F2",
          value:
            GFX_CATEGORIES?.find((s) => s.name === stat)?.stat(
              game?.away_player_stats
            ) || "0",
        });
        previewGraphic("TeamStat", {
          variable: "Box_" + i,
          value: stat?.toUpperCase() || "",
        });
      }
    }
  }

  function clear(stat) {
    if (statOne === stat) {
      setStatOne();
    }
    if (statTwo === stat) {
      setStatTwo();
    }
    if (statThree === stat) {
      setStatThree();
    }
    if (statFour === stat) {
      setStatFour();
    }
  }

  return (
    <Main>
      {GFX_CATEGORIES.map((stat) => {
        return (
          <Row>
            {game?.competition?.competition_type === "pfl europe" && (
              <>
                <Button
                  selected={statOne === stat.name}
                  onClick={() => {
                    clear(stat.name);
                    setStatOne(stat.name);
                  }}
                >
                  1
                </Button>
                <Button
                  selected={statTwo === stat.name}
                  onClick={() => {
                    clear(stat.name);
                    setStatTwo(stat.name);
                  }}
                >
                  2
                </Button>
                <Button
                  selected={statThree === stat.name}
                  onClick={() => {
                    clear(stat.name);
                    setStatThree(stat.name);
                  }}
                >
                  3
                </Button>
                <Button
                  selected={statFour === stat.name}
                  onClick={() => {
                    clear(stat.name);
                    setStatFour(stat.name);
                  }}
                >
                  4
                </Button>
              </>
            )}
            {(statOne === stat.name ||
              statTwo === stat.name ||
              statThree === stat.name ||
              statFour === stat.name ||
              game?.competition?.competition_type === "bellator") && (
              <Button
                selected={hl?.find((h) => h === stat.name)}
                onClick={() => {
                  let new_hl = [...hl];
                  let variable = "";
                  if (game?.competition?.competition_type === "bellator") {
                    setStatOne(stat.name);
                    variable = "B1_V";
                  } else {
                    if (statOne === stat.name) {
                      variable = "B1_V";
                    } else if (statTwo === stat.name) {
                      variable = "B2_V";
                    } else if (statThree === stat.name) {
                      variable = "B3_V";
                    } else if (statFour === stat.name) {
                      variable = "B4_V";
                    }
                  }
                  if (new_hl?.find((h) => h === stat.name)) {
                    if (game?.competition?.competition_type === "bellator") {
                      setHL([]);
                    } else {
                      setHL(new_hl.filter((h) => h !== stat.name));
                    }

                    previewGraphic("TeamStat", {
                      variable,
                      value: 0,
                    });
                  } else {
                    if (game?.competition?.competition_type === "bellator") {
                      setHL([stat.name]);
                    } else {
                      new_hl.push(stat.name);
                      setHL(new_hl);
                    }
                    previewGraphic("TeamStat", {
                      variable,
                      value: 1,
                    });
                  }
                }}
              >
                HL
              </Button>
            )}
            {statOne !== stat.name &&
              statTwo !== stat.name &&
              statThree !== stat.name &&
              statFour !== stat.name &&
              game?.competition?.competition_type !== "bellator" && (
                <Button></Button>
              )}

            <Stat
              game={game}
              category={stat.name}
              selectedStat={selectedStat}
              matchStats={[]}
              stat={{
                home: stat.stat(game?.home_player_stats),
                away: stat.stat(game?.away_player_stats),
              }}
              updateHomeStat={({ e }) => {}}
              updateAwayStat={({ e }) => {}}
              previewGraphic={(name, options) => {
                if (selectedStat === options.stat?.category) {
                  setSelectedStat();
                } else {
                  setSelectedStat(options.stat?.category);
                }
              }}
            />
          </Row>
        );
      })}
      {game?.competition?.competition_type === "bellator" && (
        <Row>
          <Button
            selected={hl?.find((h) => h === "GLOVE_COLOURS")}
            onClick={() => {
              setStatOne("");
              let new_hl = [...hl];
              let variable = "B1_V";
              if (new_hl?.find((h) => h === "GLOVE_COLOURS")) {
                setHL([]);
                previewGraphic("TeamStat", {
                  variable,
                  value: "5_OFF",
                });
              } else {
                setHL(["GLOVE_COLOURS"]);
                previewGraphic("TeamStat", {
                  variable,
                  value: "5_ON",
                });
              }
            }}
          >
            HL
          </Button>
          <Stat
            game={game}
            category={"Glove Colours"}
            selectedStat={selectedStat}
            matchStats={[]}
            stat={{
              home: "-",
              away: "-",
            }}
            updateHomeStat={({ e }) => {}}
            updateAwayStat={({ e }) => {}}
            previewGraphic={(name, options) => {}}
          />
        </Row>
      )}
      {game?.competition?.competition_type === "bellator" &&
        game?.championship_bar !== null &&
        game?.championship_bar !== undefined &&
        game?.championship_bar !== "" && (
          <Row>
            <Button
              selected={hl?.find((h) => h === "CHAMPIONSHIP_BAR")}
              onClick={() => {
                setStatOne("");
                let new_hl = [...hl];
                let variable = "B1_V";
                if (new_hl?.find((h) => h === "CHAMPIONSHIP_BAR")) {
                  setHL([]);
                  previewGraphic("TeamStat", {
                    variable,
                    value: "6_OFF",
                  });
                } else {
                  setHL(["CHAMPIONSHIP_BAR"]);
                  previewGraphic("TeamStat", {
                    variable: "6_TEXT",
                    value: game?.championship_bar,
                  });
                  previewGraphic("TeamStat", {
                    variable,
                    value: "6_ON",
                  });
                }
              }}
            >
              HL
            </Button>
            <Stat
              game={game}
              category={"Championship Bar"}
              selectedStat={selectedStat}
              matchStats={[]}
              stat={{
                home: "-",
                away: "-",
              }}
              updateHomeStat={({ e }) => {}}
              updateAwayStat={({ e }) => {}}
              previewGraphic={(name, options) => {}}
            />
          </Row>
        )}
      {game?.competition?.competition_type === "bellator" &&
        game?.home_fighter?.stories?.map((story) => {
          return (
            <Row>
              <Button
                selected={hl?.find((h) => h === story?.story)}
                onClick={() => {
                  setStatOne("");
                  let new_hl = [...hl];
                  let variable = "B1_V";
                  if (new_hl?.find((h) => h === story?.story)) {
                    setHL([]);
                    previewGraphic("TeamStat", {
                      variable,
                      value: "7_OFF",
                    });
                  } else {
                    setHL([story?.story]);
                    previewGraphic("TeamStat", {
                      variable: "7_TEXT",
                      value: story?.story,
                    });
                    previewGraphic("TeamStat", {
                      variable,
                      value: "7_ON",
                    });
                  }
                }}
              >
                HL
              </Button>
              <Stat
                game={game}
                category={story?.story}
                selectedStat={selectedStat}
                matchStats={[]}
                stat={{
                  home: "-",
                  away: "-",
                }}
                updateHomeStat={({ e }) => {}}
                updateAwayStat={({ e }) => {}}
                previewGraphic={(name, options) => {}}
              />
            </Row>
          );
        })}
      {game?.competition?.competition_type === "bellator" &&
        game?.away_fighter?.stories?.map((story) => {
          return (
            <Row>
              <Button
                selected={hl?.find((h) => h === story?.story)}
                onClick={() => {
                  setStatOne("");
                  let new_hl = [...hl];
                  let variable = "B1_V";
                  if (new_hl?.find((h) => h === story?.story)) {
                    setHL([]);
                    previewGraphic("TeamStat", {
                      variable,
                      value: "7_OFF",
                    });
                  } else {
                    setHL([story?.story]);
                    previewGraphic("TeamStat", {
                      variable: "7_TEXT",
                      value: story?.story,
                    });
                    previewGraphic("TeamStat", {
                      variable,
                      value: "7_ON",
                    });
                  }
                }}
              >
                HL
              </Button>
              <Stat
                game={game}
                category={story?.story}
                selectedStat={selectedStat}
                matchStats={[]}
                stat={{
                  home: "-",
                  away: "-",
                }}
                updateHomeStat={({ e }) => {}}
                updateAwayStat={({ e }) => {}}
                previewGraphic={(name, options) => {}}
              />
            </Row>
          );
        })}
      {/* {game?.competition?.competition_type === "bellator" && (
        <Row>
          <Button
            selected={hl?.find((h) => h === "CLEAR_BAR")}
            onClick={() => {
              setStatOne("");

              let variable = "B1_V";

              setHL([]);
              previewGraphic("TeamStat", {
                variable,
                value: "8",
              });
            }}
          >
            HL
          </Button>
          <Stat
            game={game}
            category={"Clear Bar"}
            selectedStat={selectedStat}
            matchStats={[]}
            stat={{
              home: "-",
              away: "-",
            }}
            updateHomeStat={({ e }) => {}}
            updateAwayStat={({ e }) => {}}
            previewGraphic={(name, options) => {}}
          />
        </Row>
      )} */}
      {false && (
        <>
          CombatIQ
          {GFX_CATEGORIES_CIQ.map((stat) => {
            return (
              <Row>
                <Button
                  selected={statOne === stat.name + "_CIQ"}
                  onClick={() => {
                    clear(stat.name + "_CIQ");
                    setStatOne(stat.name + "_CIQ");
                  }}
                >
                  1
                </Button>
                <Button
                  selected={statTwo === stat.name + "_CIQ"}
                  onClick={() => {
                    clear(stat.name + "_CIQ");
                    setStatTwo(stat.name + "_CIQ");
                  }}
                >
                  2
                </Button>
                <Button
                  selected={statThree === stat.name + "_CIQ"}
                  onClick={() => {
                    clear(stat.name + "_CIQ");
                    setStatThree(stat.name + "_CIQ");
                  }}
                >
                  3
                </Button>
                <Button
                  selected={statFour === stat.name + "_CIQ"}
                  onClick={() => {
                    clear(stat.name + "_CIQ");
                    setStatFour(stat.name + "_CIQ");
                  }}
                >
                  4
                </Button>
                {(statOne === stat.name + "_CIQ" ||
                  statTwo === stat.name + "_CIQ" ||
                  statThree === stat.name + "_CIQ" ||
                  statFour === stat.name + "_CIQ") && (
                  <Button
                    selected={hl?.find((h) => h === stat.name + "_CIQ")}
                    onClick={() => {
                      let new_hl = [...hl];
                      let variable = "";
                      if (statOne === stat.name + "_CIQ") {
                        variable = "B1_V";
                      } else if (statTwo === stat.name + "_CIQ") {
                        variable = "B2_V";
                      } else if (statThree === stat.name + "_CIQ") {
                        variable = "B3_V";
                      } else if (statFour === stat.name + "_CIQ") {
                        variable = "B4_V";
                      }
                      if (new_hl?.find((h) => h === stat.name + "_CIQ")) {
                        setHL(new_hl.filter((h) => h !== stat.name + "_CIQ"));
                        previewGraphic("TeamStat", {
                          variable,
                          value: 0,
                        });
                      } else {
                        new_hl.push(stat.name + "_CIQ");
                        setHL(new_hl);
                        previewGraphic("TeamStat", {
                          variable,
                          value: 1,
                        });
                      }
                    }}
                  >
                    HL
                  </Button>
                )}
                {statOne !== stat.name + "_CIQ" &&
                  statTwo !== stat.name + "_CIQ" &&
                  statThree !== stat.name + "_CIQ" &&
                  statFour !== stat.name + "_CIQ" && <Button></Button>}
                <Stat
                  game={game}
                  category={stat.name}
                  selectedStat={selectedStat}
                  matchStats={[]}
                  stat={{
                    home: stat?.stat(game?.home_player_ciq_stats),
                    away: stat?.stat(game?.away_player_ciq_stats),
                  }}
                  updateHomeStat={({ e }) => {}}
                  updateAwayStat={({ e }) => {}}
                  previewGraphic={(name, options) => {
                    if (selectedStat === options.stat?.category) {
                      setSelectedStat();
                    } else {
                      setSelectedStat(options.stat?.category);
                    }

                    previewGraphic(name, {
                      ...options,
                    });
                  }}
                />
              </Row>
            );
          })}
        </>
      )}
    </Main>
  );
}
