import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ScrollBar } from "../../Theme/Hyper";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
const Main = styled.div`
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;
const GamesDiv = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow-y: auto;
  height: 95vh;
`;
const Game = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.sub ? " #181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
export default function CompetitionSelect() {
  const { getCompetition } = useContext(APIContext);
  const [competitions, setCompetitions] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getCompetition({}).then((comps) => {
      setCompetitions(comps);
    });
  }, []);
  return (
    <Main>
      <Column>
        <GamesDiv>
          {competitions?.map((comp) => {
            return (
              <Game
                onClick={() => {
                  let path = location.pathname;

                  history.push({
                    pathname:
                      location.pathname.replace("/", "") + "/" + comp._id,
                  });
                }}
              >
                {comp?.name}
              </Game>
            );
          })}
        </GamesDiv>
      </Column>
    </Main>
  );
}
