import moment from "moment";

const CATEGORIES = [
  "Landed Arm Strikes",
  "Landed Leg Strikes",
  "Landed Ground Strikes",
  "Takedown Success",
  "Submission Success",
  "Missed Arm Strikes",
  "Missed Leg Strikes",
  "Missed Ground Strikes",
  "Takedown Attempted",
  "Submission Attempted",
  "Knockdown Success",
];
const CATEGORIES_CIQ = ["TOT", "ARM", "LEG", "GD", "TD", "KD", "SUB"];
const GFX_CATEGORIES = [
  {
    name: "Arm Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[5],
        landed: CATEGORIES[0],
        stats,
      });
    },
  },
  {
    name: "Leg Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[6],
        landed: CATEGORIES[1],
        stats,
      });
    },
  },
  {
    name: "Ground Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[7],
        landed: CATEGORIES[2],
        stats,
      });
    },
  },
  {
    name: "Knockdowns",
    stat: (stats) => {
      return (
        stats?.reduce((prev, curr) => {
          return prev + (curr?.[CATEGORIES[10]] || 0);
        }, 0) || 0
      );
    },
  },
  {
    name: "Takedowns",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[8],
        landed: CATEGORIES[3],
        stats,
      });
    },
  },
];

const calPercentage = function ({ missed, landed, stats, round }) {
  let missed_total = stats
    ?.filter((r) => r.round === round || !round)
    ?.reduce((prev, curr) => {
      return prev + (curr?.[missed] || 0);
    }, 0);
  let landed_total = stats
    ?.filter((r) => r.round === round || !round)
    ?.reduce((prev, curr) => {
      return prev + (curr?.[landed] || 0);
    }, 0);
  let output = (landed_total || 0) + "/";

  output += (missed_total || 0) + (landed_total || 0);

  return output;
};

const GFX_CATEGORIES_CIQ = [
  {
    name: "Arm Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[1] + ".Succ",
        missed: CATEGORIES_CIQ[1] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Leg Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[2] + ".Succ",
        missed: CATEGORIES_CIQ[2] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Ground Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[3] + ".Succ",
        missed: CATEGORIES_CIQ[3] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Knockdowns",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[5] + ".Succ",
        missed: CATEGORIES_CIQ[5] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Takedowns",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[4] + ".Succ",
        missed: CATEGORIES_CIQ[4] + ".Att",
        stats: stats,
      });
    },
  },
];

function calPercentageCIQ({ missed, landed, stats, round }) {
  let missed_total = stats
    ?.filter((r) => r?.round === round || !round)
    ?.reduce((prev, curr) => {
      var value = missed?.split(".").reduce((a, b) => a?.[b], curr);

      return prev + (value || 0);
    }, 0);
  let landed_total = stats
    ?.filter((r) => r?.round === round || !round)
    ?.reduce((prev, curr) => {
      var value = landed?.split(".").reduce((a, b) => a?.[b], curr);
      return prev + (value || 0);
    }, 0);
  let output = (landed_total || 0) + "/";

  output += (missed_total || 0) + (landed_total || 0);

  return output;
}
export class Clock {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    vizCommand,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.vizCommand = vizCommand;
    this.name = "CLOCK";
    this.scene = "Clock";
    this.data = [];
    this.page = 9000;
  }

  async preview({ options }) {
    this.onAir = false;

    await this.updateData({ options });

    await this.sendPreview("page:read " + this.page);
  }
  async animate({ options }) {
    this.onAir = true;
    await this.updateData({ options });

    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    if (options.type === "END") {
      if (this.onAir) {
        this.sendAnimateOff({ page: this.page });
      }
    } else {
      this.sendAnimateOff({ page: this.page });
    }
    this.onAir = false;
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  async updateData({ options }) {
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      try {
        xml.payload.field.find((item) => {
          return item.attr.name === "1000"; // Number
        }).value = options.game?.gold_fight || "0";
      } catch (err) {}
      xml.payload.field.find((item) => {
        return item.attr.name === "1004"; // Number
      }).value = options.game?.title || "";

      let rgb = this.hexToRgb(options.game?.home_fighter?.shorts_colour);
      xml.payload.field.find((item) => {
        return item.attr.name === "1001"; // Number
      }).value = rgb?.r + "," + rgb?.g + "," + rgb?.b;

      rgb = this.hexToRgb(options.game?.away_fighter?.shorts_colour);
      xml.payload.field.find((item) => {
        return item.attr.name === "1002"; // Number
      }).value = rgb?.r + "," + rgb?.g + "," + rgb?.b;

      xml.payload.field.find((item) => {
        return item.attr.name === "1101"; // Number
      }).value =
        options.game?.home_fighter?.short_name?.toUpperCase() ||
        options.game?.home_fighter?.last_name?.toUpperCase() ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "1104"; // Number
      }).value =
        "IMAGE*/PFL2023/_Globals/ScoreboardFlags/" +
        (options.game?.home_fighter?.nationality?.code || "");

      xml.payload.field.find((item) => {
        return item.attr.name === "1201"; // Number
      }).value =
        options.game?.away_fighter?.short_name?.toUpperCase() ||
        options.game?.away_fighter?.last_name?.toUpperCase() ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "1204"; // Number
      }).value =
        "IMAGE*/PFL2023/_Globals/ScoreboardFlags/" +
        (options.game?.away_fighter?.nationality?.code || "");

      let period = options.period;

      xml.payload.field.find((item) => {
        return item.attr.name === "1030";
      }).value = period.number;

      let pauseTime = 0;

      period.pause &&
        period.pause.forEach((pause) => {
          if (pause.end) {
            pauseTime += pause.end - pause.start;
          }
        });
      let latestPause = period.pause && period.pause[period.pause.length - 1];
      if (latestPause && latestPause.end) {
        latestPause = null;
      }
      let time = moment.duration(
        ((latestPause && latestPause.start) || Date.now()) -
          period.kick_off -
          pauseTime
      );

      let round_minutes =
        options?.game?.round_minutes !== undefined ||
        options?.game?.round_minutes !== null
          ? options?.game?.round_minutes
          : 5;

      let count_down = moment.duration(
        Math.max(0, 1000 * 60 * round_minutes - time.asMilliseconds())
      );

      let seconds =
        count_down.seconds() +
        count_down.minutes() * 60 +
        count_down.hours() * 60 * 60;
      seconds = Math.max(0, seconds);

      if (!latestPause) {
        xml.payload.field.find((item) => {
          return item.attr.name === "1007"; // start clock
        }).value =
          "*TIME SET " +
          seconds +
          ".000,*LIMIT SET 0.000,*DIRECTION SET DOWN,START,UPDATE_TEXT SET 1";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "1007"; // start clock
        }).value = "*TIME SET " + seconds + ".000,STOP";
      }
      const statOne = window.localStorage
        .getItem("stat_one")
        ?.replaceAll('"', "");
      const statTwo = window.localStorage
        .getItem("stat_two")
        ?.replaceAll('"', "");
      const statThree = window.localStorage
        .getItem("stat_three")
        ?.replaceAll('"', "");
      const statFour = window.localStorage
        .getItem("stat_four")
        ?.replaceAll('"', "");

      for (let i = 1; i <= 4; i++) {
        let stat = "";
        switch (i) {
          case 1:
            stat = statOne;
            break;
          case 2:
            stat = statTwo;
            break;
          case 3:
            stat = statThree;
            break;
          case 4:
            stat = statFour;
            break;
          default:
        }
        if (stat?.indexOf("_CIQ") > -1) {
          xml.payload.field.find((item) => {
            return item.attr.name === "13" + i + "3";
          }).value =
            GFX_CATEGORIES_CIQ?.find(
              (s) => s.name === stat?.replace("_CIQ", "")
            )?.stat(options?.game?.home_player_stats) || "";

          xml.payload.field.find((item) => {
            return item.attr.name === "13" + i + "6";
          }).value =
            GFX_CATEGORIES?.find(
              (s) => s.name === stat?.replace("_CIQ", "")
            )?.stat(options?.game?.away_player_stats) || "";
          xml.payload.field.find((item) => {
            return item.attr.name === "13" + i + "0";
          }).value = stat?.replace("_CIQ", "")?.toUpperCase() || "";
        } else {
          debugger;
          xml.payload.field.find((item) => {
            return item.attr.name === "13" + i + "3";
          }).value =
            GFX_CATEGORIES?.find((s) => s.name === stat)?.stat(
              options?.game?.home_player_stats
            ) || "0";
          xml.payload.field.find((item) => {
            return item.attr.name === "13" + i + "6";
          }).value =
            GFX_CATEGORIES?.find((s) => s.name === stat)?.stat(
              options?.game?.away_player_stats
            ) || "0";

          xml.payload.field.find((item) => {
            return item.attr.name === "13" + i + "0";
          }).value = stat?.toUpperCase() || "";
        }
      }

      await this.updateVizPage({
        page: this.page,
        data: xml,
        escape: false,
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  async update({ options }) {
    if (options.type === "PAUSE" || options.type === "RESUME") {
      let xml = await this.getVizPage({ page: this.page }).catch((err) => {
        console.log(err);
      });
      if (xml) {
        let period = options.period;

        let pauseTime = 0;

        period.pause &&
          period.pause.forEach((pause) => {
            if (pause.end) {
              pauseTime += pause.end - pause.start;
            }
          });
        let latestPause = period.pause && period.pause[period.pause.length - 1];
        if (latestPause && latestPause.end) {
          latestPause = null;
        }
        let time = moment.duration(
          ((latestPause && latestPause.start) || Date.now()) -
            period.kick_off -
            pauseTime
        );

        let count_down = moment.duration(
          Math.max(0, 300000 - time.asMilliseconds())
        );

        let seconds =
          count_down.seconds() +
          count_down.minutes() * 60 +
          count_down.hours() * 60 * 60;
        seconds = Math.max(0, seconds);
        if (options.type === "PAUSE") {
          // xml.payload.field.find((item) => {
          //   return item.attr.name === "1007"; // start clock
          // }).value = "STOP";
        } else if (options.type === "RESUME") {
          xml.payload.field.find((item) => {
            return item.attr.name === "1007"; // start clock
          }).value =
            "*TIME SET " + seconds + ",*DIRECTION DOWN,START,UPDATE_TEXT SET 1";
        }

        if (options.type === "PAUSE") {
          await this.vizCommand({ viz_command: "10 CLOCK0 STOP" });
        } else if (options.type === "RESUME") {
          await this.vizCommand({ viz_command: "10 CLOCK0 CONT" });
        }

        await this.updateVizPage({
          page: this.page,
          data: xml,
          escape: false,
        }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
}
