import React, { useContext } from "react";
import styled from "styled-components";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { Button } from "../../Theme/Hyper";
import { ControlContext } from "../../contexts/ControlContext";

const TeamNamePanel = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 13vh;
  margin-bottom: 1em;
`;
const Team = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  width: 40%;
  align-items: center;
  height: 54px;
`;
const Middle = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -10px;
`;
const TeamName = styled.div`
  padding-left: ${(props) => (props.home ? "0.5em" : "")};
  margin-left: ${(props) => (props.home ? "auto" : "")};
  display: flex;
  font-size: 1.5em;

  width: 100%;
`;
const Score = styled.div`
  display: flex;
  font-size: 3em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-items: center;
  justify-content: center;

  border-radius: 2px;
`;
const Row = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  box-sizing: border-box;
  justify-content: center;
`;
export default function TeamNames({
  setGoalScored,
  game,
  setSelectedPlayer,
  isFruit = false,
}) {
  const { setView, selectedTeam, setGoal } = useContext(UIContext);
  const { updateGame } = useContext(APIContext);
  const { clear } = useContext(ControlContext);

  let homeBGColour = game.home_fighter?.shorts_colour || "";
  let awayBGColour = game.away_fighter?.shorts_colour || "";

  let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
  let conversion_points =
    game?.competition?.competition_type === "union" ? 2 : 2;
  let penalty_kick_points =
    game?.competition?.competition_type === "union" ? 3 : 2;
  let penalty_try_points =
    game?.competition?.competition_type === "union" ? 7 : 4;
  let drop_goal_points =
    game?.competition?.competition_type === "union" ? 3 : 1;
  return (
    <TeamNamePanel>
      <Row>
        {game?.title}
        {game?.gold_fight === 1 ? "- Gold Fight" : ""}
      </Row>
      <Row>
        <Team
          style={{
            borderBottom:
              "3px solid " + (homeBGColour ? homeBGColour : "rgba(0,0,0,0)"),
            opacity:
              !selectedTeam || selectedTeam._id === game.home_fighter._id
                ? "1"
                : ".2",
          }}
        >
          <TeamName home style={{ display: "flex", justifyContent: "end" }}>
            {game?.home_fighter?.first_name} {game?.home_fighter?.last_name} -{" "}
            {game?.home_fighter?.nationality?.code}
          </TeamName>
        </Team>
        <Middle>
          {/* {!isFruit && (
          <Button
            onClick={() => {
              clear();
            }}
          >
            CLEAR ALL GFX <br />
            (Ctl+Shift+Q)
          </Button>
        )} */}
        </Middle>
        <Team
          style={{
            borderBottom:
              "3px solid " + (awayBGColour ? awayBGColour : "rgba(0,0,0,0)"),
            opacity:
              !selectedTeam || selectedTeam._id === game.away_fighter._id
                ? "1"
                : ".2",
          }}
        >
          <TeamName>
            {game.away_fighter?.first_name} {game.away_fighter?.last_name} -{" "}
            {game?.away_fighter?.nationality?.code}
          </TeamName>
        </Team>
      </Row>
    </TeamNamePanel>
  );
}
