import React, { useContext, useEffect, useState } from "react";
import Game from "../Fruit/Game";
import styled from "styled-components";
import TeamNames from "../Fruit/TeamNames";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { APIContext } from "../../contexts/APIContext";
import Clock from "../Fruit/Clock";
import NonStrikeStats from "../Fruit/NonStrikeStats";
import PFL from "../../Images/PFL_EUROPE.png";
import BELLATOR from "../../Images/Bellator_logo.png";
import NonStrikeStatsCIQ from "../Fruit/NonStrikeStatsCIQ";
import ClockCIQ from "../Fruit/ClockCIQ";
const GAME_BY_ID = gql`
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      oval_ID
      time
      description
      date
      home_team_kit
      away_team_kit
      stadium {
        opta_name
        name
      }
      competition {
        oval_ID
        name
        competition_type
        badge
        secondary_badge
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_player_stats
      away_player_stats
      home_player_ciq_stats
      away_player_ciq_stats
      home_team_stats
      away_team_stats
      home_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
        nationality {
          code
        }
      }
      away_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
        nationality {
          code
        }
      }
      live_data
      home_phase
      away_phase
      metres_gained
      number_of_rounds
      round_minutes
      gold_fight
      title
      season {
        _id
        oval_ID
      }
      commentators {
        first_name
        last_name
      }
      home_sets
      away_sets
      ciq_stats
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      live_data
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      home_player_stats
      away_player_stats
      home_player_ciq_stats
      away_player_ciq_stats
      home_team_stats
      away_team_stats
      season {
        _id
        oval_ID
      }
      commentators {
        first_name
        last_name
      }
      home_phase
      away_phase
      metres_gained
      home_player_stats
      away_player_stats
      home_player_ciq_stats
      away_player_ciq_stats
      home_team_stats
      away_team_stats
      home_sets
      away_sets
      number_of_rounds
      round_minutes
      gold_fight
      title
      ciq_stats
    }
  }
`;
const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin: 1em;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 100%;
`;

const Logo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export default function Fruit({ setSelectedPlayer, selectedPlayer }) {
  const { competition } = useParams();
  const [gameID, setGameID] = useState();
  const [game, setGame] = useState();
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);
  const { getCompetition } = useContext(APIContext);
  const [homeManager, setHomeManager] = useState();
  const [awayManager, setAwayManager] = useState();

  useEffect(() => {
    let interval;
    getCompetition().then((data) => {
      setGameID(data?.find((c) => c?._id === competition)?.active_fight?._id);
    });
    interval = setInterval(
      () =>
        getCompetition().then((data) => {
          setGameID(
            data?.find((c) => c?._id === competition)?.active_fight?._id
          );
        }),
      10000
    );

    return () => clearInterval(interval);
  }, [competition]);

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;

    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };
          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
    }
  }, [data]);

  if (!game) return <div>Loading</div>;

  return (
    game && (
      <Main>
        <Content>
          <TeamNames
            setSelectedPlayer={setSelectedPlayer}
            homeTeam={game.home_team}
            awayTeam={game.away_team}
            game={game}
          />
          <Row>
            <Column>
              <Game
                setHomeManager={setHomeManager}
                setAwayManager={setAwayManager}
                game={game}
                setSelectedPlayer={setSelectedPlayer}
                selectedPlayer={selectedPlayer}
              />
            </Column>
            <Column style={{ width: "40%" }}>
              <ActionPanel>
                <Column>
                  <Logo>
                    <img
                      src={
                        game?.competition?.competition_type === "bellator"
                          ? BELLATOR
                          : PFL
                      }
                      alt="PFL Logo"
                      height="auto"
                      width={
                        game?.competition?.competition_type === "bellator"
                          ? 300
                          : 300
                      }
                    />
                  </Logo>

                  <Switch>
                    <Route path="/fruit/combatiq">
                      <ClockCIQ game={game} />
                      <NonStrikeStatsCIQ game={game} />
                    </Route>
                    <Route path="/">
                      <Clock game={game} />
                      <NonStrikeStats game={game} />
                    </Route>
                  </Switch>
                </Column>
              </ActionPanel>
            </Column>
          </Row>
        </Content>
      </Main>
    )
  );
}
