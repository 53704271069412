import React, { useContext } from "react";
import styled from "styled-components";
import { ClockContext } from "../../contexts/ClockContext";
import { ControlContext } from "../../contexts/ControlContext";

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  padding: 0.5em;
  color: #474e5f;
  align-items: center;
`;

const Connected = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  background-color: ${(props) => (props.connected ? "#0adb93" : "red")};
  border-radius: 50%;
  margin: 1em;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1em;
`;

function ServerStatus() {
  const {
    wsStatus,
    hyperLink,
    mse,
    trio,
    trioShow,
    vizProfile,
    showID,
    hyperClock,
  } = useContext(ControlContext);
  const { wsStatus: clockStatus } = useContext(ClockContext);
  return (
    <ActionPanel>
      <Column>
        <Connected connected={wsStatus === 1} />
        Hyper Link Server: {hyperLink}
      </Column>
      <Column>MSE: {mse}</Column>
      <Column>Trio IP: {trio}</Column>
      <Column>
        {" "}
        <Connected connected={showID} />
        Trio Show: {trioShow}
      </Column>
      <Column>Viz Profile: {vizProfile}</Column>
      <Column>
        <Connected connected={clockStatus === 1} />
        Stadium Clock: {hyperClock}
      </Column>
    </ActionPanel>
  );
}

export default ServerStatus;
