import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import { Button, RoundButton } from "../../../Theme/Hyper";
import { APIContext } from "../../../contexts/APIContext";
import Xbox from "../../../Images/Xbox2.png";
import useLocalStorage from "../../../Hooks/useLocalStorage";
import Popup from "../../UI/Popup/Popup";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 100px;
`;

const TeamNamePanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  box-sizing: border-box;
  /* justify-content: center; */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 7vh;
  font-size: 24px;
`;
const Team = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  width: 40%;
  align-items: center;
  height: 54px;
  font-size: 24px;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatGroup = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 25%; */
`;
const IPadButton = styled(Button)`
  padding: 17px;
  margin: 10px;
  min-width: 130px;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => (props.selected ? "#db0a41" : "")};
  :hover {
    background-color: ${(props) => (!props.selected ? "#4c5264" : "")};
  }
`;
const BlankButton = styled.div`
  min-width: 150px;
`;
const CancelButton = styled(Button)`
  padding: 0px;
  margin: 10px;
  margin-top: 0px;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
  background-color: #22252c;
  :hover {
    background-color: ${(props) => (!props.selected ? "#4c5264" : "")};
  }
`;

const Title = styled.div``;
const Symbol = styled.div`
  font-size: 40px;
`;
const MinusSymbol = styled.div`
  font-size: 20px;
`;
const Value = styled.div`
  font-size: 40px;
`;
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        stats
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          short_name
          short_name_upper
          nationality {
            _id
            code
            name
          }
          image
          stories
          oval_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
    away {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        stats
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          short_name
          short_name_upper
          nationality {
            _id
            code
            name
          }
          image
          stories
          oval_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      home_team_kit
      away_team_kit
      stadium {
        opta_name
        name
      }
      competition {
        name
        competition_type
        badge
        secondary_badge
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_player_stats
      away_player_stats
      home_player_ciq_stats
      away_player_ciq_stats
      home_team_stats
      away_team_stats
      lineup {
        ...LINEUP_FIELDS
      }
      home_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
      }
      away_fighter {
        _id
        first_name
        last_name
        short_name
        image
        image_2
        stories
        shorts_colour
      }
      number_of_rounds
      gold_fight
      title
      live_data
      home_phase
      away_phase
      metres_gained
      home_player_stats
      away_player_stats
      home_player_ciq_stats
      away_player_ciq_stats
      home_team_stats
      away_team_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      home_player_stats
      away_player_stats
      home_player_ciq_stats
      away_player_ciq_stats
      home_team_stats
      away_team_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      home_phase
      away_phase
      metres_gained
      home_player_stats
      away_player_stats
      home_player_ciq_stats
      away_player_ciq_stats
      home_team_stats
      number_of_rounds
      away_team_stats
    }
  }
`;

const CATEGORIES = [
  "Landed Arm Strikes",
  "Landed Leg Strikes",
  "Landed Ground Strikes",
  "Takedown Success",
  "Missed Arm Strikes",
  "Missed Leg Strikes",
  "Missed Ground Strikes",
  "Takedown Attempted",
  "Submission Success",
  "Submission Attempted",
  "Knockdown Success",
  "Knockdown Attemped",
];

const gamepadAPI = {
  controller: {},
  connect(evt) {
    gamepadAPI.controller = evt.gamepad;
    console.log("Gamepad connected.");
  },
  disconnect(evt) {
    delete gamepadAPI.controller;
    console.log("Gamepad disconnected.");
  },
  update() {
    // Clear the buttons cache
    gamepadAPI.buttonsCache = [];

    // Move the buttons status from the previous frame to the cache
    for (let k = 0; k < gamepadAPI.buttonsStatus.length; k++) {
      gamepadAPI.buttonsCache[k] = gamepadAPI.buttonsStatus[k];
    }

    // Clear the buttons status
    gamepadAPI.buttonsStatus = [];

    // Get the gamepad object
    const c = navigator.getGamepads()[0] || {};

    // Loop through buttons and push the pressed ones to the array
    const pressed = [];

    if (c.buttons) {
      for (let b = 0; b < c.buttons.length; b++) {
        if (c.buttons[b].pressed) {
          pressed.push(gamepadAPI.buttons[b]);
        }
      }
    }

    // Loop through axes and push their values to the array
    const axes = [];
    if (c.axes) {
      for (let a = 0; a < c.axes.length; a++) {
        axes.push(c.axes[a].toFixed(2));
      }
    }

    // Assign received values
    gamepadAPI.axesStatus = axes;
    gamepadAPI.buttonsStatus = pressed;

    // Return buttons for debugging purposes
    return pressed;
  },
  buttonPressed(button, hold) {
    let newPress = false;

    // Loop through pressed buttons
    for (let i = 0; i < gamepadAPI.buttonsStatus.length; i++) {
      //console.log(gamepadAPI.buttonsStatus[i]);
      // If we found the button we're looking for
      if (gamepadAPI.buttonsStatus[i] === button) {
        // Set the boolean variable to true
        newPress = true;

        // If we want to check the single press
        if (!hold) {
          // Loop through the cached states from the previous frame
          if (gamepadAPI.buttonsCache?.findIndex((b) => b === button) > -1) {
            newPress = false;
          }
          // for (let j = 0; j < gamepadAPI.buttonsCache.length; j++) {
          //   // If the button was already pressed, ignore new press
          //   newPress = gamepadAPI.buttonsCache[j] !== button;
          // }
        }
      }
    }
    if (newPress) {
      console.log(button, newPress);
    }
    return newPress;
  },
  buttons: [
    "A",
    "B",
    "X",
    "Y",
    "LB",
    "RB",
    "Left-Trigger",
    "Right-Trigger",
    "LB",
    "RB",
    "Power",
    "---",
    "UP",
    "DOWN",
    "LEFT",
    "RIGHT",
  ],
  buttonsCache: [],
  buttonsStatus: [],
  axesStatus: [],
};

const Controller = styled.div`
  display: flex;
  position: relative;
`;

const MappedKey = styled.div`
  position: absolute;
  color: #383838;
  background-color: #4fe8b0;
  padding: 5px;
  text-align: right;
`;
const XboxController = styled.img``;
const MappingItem = styled.div`
  display: flex;
  background-color: ${(props) => (props.selected ? "#4fe8b0" : "")};
  color: ${(props) => (props.selected ? "#000" : "")};
  padding: 4px;
`;

const TeamName = styled.div`
  padding-left: ${(props) => (props.home ? "0.5em" : "")};
  margin-left: ${(props) => (props.home ? "auto" : "")};
  display: flex;
  font-size: 1.5em;
  width: 100%;
`;

let local_selectedPlayerStats;

function PlayerStats() {
  let { gameID, team_type } = useParams();
  const [mappingView, setMappingView] = useState();

  const [selectedPlayerStats, setSelectedPlayerStats] = useState();
  const [mapping, setMapping] = useLocalStorage("xbox-mapping", {
    "Right-Trigger": null,
    "Left-Trigger": null,
    RB: null,
    LB: null,
    A: null,
    B: null,
  });
  const [game, setGame] = useState();
  const [roundNumber, setRoundNumber] = useLocalStorage(
    gameID + "_round_number",
    1
  );
  const [connected, setConnected] = useState(false);
  const { updateGame } = useContext(APIContext);
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);
  const [confirmClear, setConfirmClear] = useState(false);

  useEffect(() => {
    window.addEventListener("gamepadconnected", (evt) => {
      gamepadAPI.connect(evt);
      setConnected(true);
    });
    window.addEventListener("gamepaddisconnected", (evt) => {
      gamepadAPI.disconnect(evt);
      setConnected(false);
    });
  }, []);

  const updateStat = useCallback(
    (keyPressed, stats) => {
      let newPlayerStats = [...JSON.parse(JSON.stringify(stats || []))];
      let new_obj = [...(newPlayerStats || [])];

      let round_num = roundNumber;

      if (gamepadAPI.buttonPressed(keyPressed) && game) {
        let index = new_obj?.findIndex((r) => r.round === round_num);
        if (index > -1) {
          new_obj[index][mapping[keyPressed]] =
            (new_obj[index][mapping[keyPressed]] || 0) + 1;
        } else {
          new_obj.push({
            round: round_num,
            [mapping[keyPressed]]: 1,
          });
        }

        setSelectedPlayerStats(new_obj);
        updateGame({
          id: game._id,
          [team_type + "_player_stats"]: new_obj,
        });
      }
      return new_obj;
    },
    [game, roundNumber]
  );

  const clearData = useCallback(() => {
    local_selectedPlayerStats = [];
    setSelectedPlayerStats([]);
    updateGame({
      id: game._id,
      [team_type + "_player_stats"]: [],
    });
  }, [game, selectedPlayerStats]);

  useEffect(() => {
    let raf;
    function updateStatus() {
      let pressed = gamepadAPI.update();
      // if (pressed.length > 0) {
      //   console.log(pressed);
      // }

      let stats = local_selectedPlayerStats || selectedPlayerStats;
      for (let i = 0; i < Object.keys(mapping).length; i++) {
        stats = updateStat(Object.keys(mapping)[i], stats);
      }
      local_selectedPlayerStats = stats;
      raf = requestAnimationFrame(updateStatus);
    }

    updateStatus();

    return () => {
      cancelAnimationFrame(raf);
    };
  }, [mapping, updateStat]);

  useEffect(() => {
    if (gameID) {
      local_selectedPlayerStats = [];
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;

    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
      debugger;
      if (!selectedPlayerStats) {
        setSelectedPlayerStats(data.gameById?.[team_type + "_player_stats"]);
        local_selectedPlayerStats =
          data.gameById?.[team_type + "_player_stats"];
      }
    }
  }, [data]);

  function calcTotal({ variable }) {
    return selectedPlayerStats?.reduce((prev, curr) => {
      return prev + (curr[variable] || 0);
    }, 0);
  }

  return (
    <Main>
      <Row>
        <TeamNamePanel>
          <Team
            style={{
              borderBottom:
                "3px solid " +
                (game?.[team_type + "_fighter"]?.shorts_colour
                  ? game?.[team_type + "_fighter"]?.shorts_colour
                  : "rgba(0,0,0,0)"),
            }}
          >
            <TeamName>
              {game?.[team_type + "_fighter"]?.first_name}{" "}
              {game?.[team_type + "_fighter"]?.last_name}
              {new Array(game?.number_of_rounds)?.fill(1)?.map((_, index) => {
                return (
                  <RoundButton
                    selected={index + 1 === roundNumber}
                    onClick={() => {
                      setRoundNumber(index + 1);
                    }}
                  >
                    Round {index + 1}
                  </RoundButton>
                );
              })}
            </TeamName>
          </Team>
        </TeamNamePanel>

        {confirmClear === false && (
          <Button
            onClick={() => {
              setConfirmClear(true);
            }}
          >
            Clear Data
          </Button>
        )}
        {confirmClear && (
          <>
            Are you sure you want to clear data?{" "}
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setConfirmClear(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setConfirmClear(false);
                clearData();
              }}
            >
              Yes
            </Button>
          </>
        )}
      </Row>
      {/* <Row>
        {connected && <span>connected</span>}
        {!connected && <span>not connected</span>}
      </Row> */}
      <Row>
        <Controller>
          <XboxController
            src={Xbox}
            alt="Xbox controller"
            width="840px"
            height="455px"
          />
          <MappedKey
            style={{ top: "36px", right: "660px", zIndex: 900 }}
            onClick={() => {
              setMappingView("Left-Trigger");
            }}
          >
            {mapping["Left-Trigger"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "84px", right: "700px" }}
            onClick={() => {
              setMappingView("LB");
            }}
          >
            {mapping["LB"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "36px", left: "520px" }}
            onClick={() => {
              setMappingView("Right-Trigger");
            }}
          >
            {mapping["Right-Trigger"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "84px", left: "562px" }}
            onClick={() => {
              setMappingView("RB");
            }}
          >
            {mapping["RB"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "141px", left: "647px" }}
            onClick={() => {
              setMappingView("X");
            }}
          >
            {mapping["X"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "225px", left: "601px" }}
            onClick={() => {
              setMappingView("Y");
            }}
          >
            {mapping["Y"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "353px", left: "601px" }}
            onClick={() => {
              setMappingView("A");
            }}
          >
            {mapping["A"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "304px", left: "644px" }}
            onClick={() => {
              setMappingView("B");
            }}
          >
            {mapping["B"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "198px", left: "6px" }}
            onClick={() => {
              setMappingView("UP");
            }}
          >
            {mapping["UP"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "280px", left: "-20px" }}
            onClick={() => {
              setMappingView("LEFT");
            }}
          >
            {mapping["LEFT"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "419px", left: "225px" }}
            onClick={() => {
              setMappingView("DOWN");
            }}
          >
            {mapping["DOWN"] || "Not Mapped"}
          </MappedKey>
          <MappedKey
            style={{ top: "385px", left: "362px" }}
            onClick={() => {
              setMappingView("RIGHT");
            }}
          >
            {mapping["RIGHT"] || "Not Mapped"}
          </MappedKey>
        </Controller>

        <Row style={{ width: "45%" }}>
          {CATEGORIES.map((c) => {
            return (
              <StatGroup>
                <IPadButton
                  onClick={() => {
                    let newPlayerStats = [
                      ...(JSON.parse(JSON.stringify(selectedPlayerStats)) ||
                        []),
                    ];

                    let new_obj = [...(newPlayerStats || [])];
                    let index = new_obj?.findIndex(
                      (r) => r.round === roundNumber
                    );
                    if (index > -1) {
                      new_obj[index][c] = (new_obj[index][c] || 0) + 1;
                    } else {
                      new_obj.push({
                        round: roundNumber,
                        [c]: 1,
                      });
                    }
                    setSelectedPlayerStats(new_obj);
                    local_selectedPlayerStats = new_obj;
                    updateGame({
                      id: game._id,
                      [team_type + "_player_stats"]: new_obj,
                    });
                  }}
                >
                  <Title>{c}</Title>
                  <Title>Total: {calcTotal({ variable: c })}</Title>
                  <Value>
                    {selectedPlayerStats?.find(
                      (r) => r.round === roundNumber
                    )?.[c] || 0}
                  </Value>
                  <Symbol>+</Symbol>
                </IPadButton>
                <CancelButton
                  onClick={() => {
                    let newPlayerStats = [
                      ...(JSON.parse(JSON.stringify(selectedPlayerStats)) ||
                        []),
                    ];

                    let new_obj = [...(newPlayerStats || [])];
                    let index = new_obj?.findIndex(
                      (r) => r.round === roundNumber
                    );
                    if (index > -1) {
                      new_obj[index][c] = Math.max(
                        (new_obj[index][c] || 0) - 1,
                        0
                      );
                    } else {
                      new_obj.push({
                        round: roundNumber,
                        [c]: 0,
                      });
                    }
                    setSelectedPlayerStats(new_obj);
                    local_selectedPlayerStats = new_obj;
                    updateGame({
                      id: game._id,
                      [team_type + "_player_stats"]: new_obj,
                    });
                  }}
                >
                  <MinusSymbol>-</MinusSymbol>
                </CancelButton>
              </StatGroup>
            );
          })}
          <StatGroup>
            <BlankButton />
          </StatGroup>
        </Row>
      </Row>

      {mappingView && (
        <MappedItem
          mappingView={mappingView}
          mapping={mapping}
          onCancel={() => {
            setMappingView();
          }}
          onSave={({ key, category }) => {
            let new_mappings = { ...mapping };
            new_mappings[key] = category;
            setMapping(new_mappings);
            setMappingView();
          }}
        />
      )}
    </Main>
  );
}

function MappedItem({ mappingView, mapping, onCancel, onSave }) {
  const [localCat, setLocalCat] = useState();
  useEffect(() => {
    setLocalCat(mapping[mappingView]);
  }, [mappingView]);
  return (
    <Popup
      min_width="500px"
      showConfirmButton={true}
      title={"Mapping"}
      cancelButton="Close"
      onCancel={() => {
        onCancel();
      }}
      onSave={() => {
        onSave({ key: mappingView, category: localCat });
      }}
    >
      {CATEGORIES.map((cat) => {
        return (
          <MappingItem
            selected={cat === localCat}
            onClick={() => {
              setLocalCat(cat);
            }}
          >
            {cat}
          </MappingItem>
        );
      })}
    </Popup>
  );
}

export default PlayerStats;
