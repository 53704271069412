import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const Main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.primaryBackgroundColour};
  opacity: 0.7;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid grey;
  padding: 0.5em;
  font-weight: 100;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.panelColour};
  min-width: ${({ min_width }) => min_width}};
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -23%;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
`;
const CancelButton = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.secondaryBackgroundColour};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;
const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.highlightColour};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
`;
const Warning = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1em;
`;
const Input = styled.input`
  display: flex;
  justify-content: center;
  margin: 0.5em;
  background-color: ${(props) =>
    props.overwrite ? "#db0a41" : props.theme.secondaryBackgroundColour};
  border-radius: 4px;
  outline: none;
  border: none;
  color: #fff;
  padding: 0.5em;
  font-weight: 100;
  font-size: 1.2em;
`;
export default function Popup({
  onCancel,
  onSave,
  title,
  message,
  confirmButton,
  cancelButton,
  showConfirmButton = true,
  children,
  min_width = "400px",
}) {
  useEffect(() => {
    function keydown(e) {
      if (e.keyCode === 27) {
        onCancel();
        e.preventDefault();
      } else if (e.keyCode === 13) {
        onSave();

        e.preventDefault();
      }
    }
    window.addEventListener("keydown", keydown);

    return () => {
      window.removeEventListener("keydown", keydown);
    };
  }, []);

  return (
    <>
      <Screen></Screen>
      <Main>
        <Content min_width={min_width}>
          <Header>{title}</Header>
          <Warning>{message}</Warning>
          {children}
          <Buttons>
            <CancelButton
              onClick={() => {
                onCancel();
              }}
            >
              {cancelButton || "Cancel"}
            </CancelButton>
            {showConfirmButton && (
              <Button
                onClick={() => {
                  onSave();
                }}
              >
                {confirmButton || "Confirm"}
              </Button>
            )}
          </Buttons>
        </Content>
      </Main>
    </>
  );
}
