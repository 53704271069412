import moment from "moment";

export class TeamStat {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "TeamStat";
    this.scene = "TeamStat";
    this.data = [];
    this.page = 1;
  }

  async preview({ options }) {
    this.vizSharedMemory({
      shared_memory:
        '10 VIZ_COMMUNICATION*MAP SET_STRING_ELEMENT "' +
        options.variable +
        '" ' +
        options?.value,
    });
  }
  async animate({ options }) {}
  animateOff({ options }) {}

  update({ options }) {}
}
