import React, { useState, useEffect, useContext, useCallback } from "react";
import { ControlContext } from "./ControlContext";
import BasicPack from "../Graphics/BasicPack/Graphics.js";

// const viz = new Viz("FOOTBALL_2020");
const viz = {};
const GraphicsContext = React.createContext([{}, () => { }]);

const GraphicsProvider = (props) => {
  const {
    animate,
    preview,
    update,
    animateOff,
    clearPreview,
    triggerAnimation,
    previewTrio,
    getVizPage,
    updateVizPage,
    takeVizPage,
    takeOutVizPage,
    vizSharedMemory,
    vizCommand
  } = useContext(ControlContext);
  const [graphics, setGraphics] = useState();
  const [graphicInPreview, setGraphicInPreview] = useState();
  const [style, setStyle] = useState("BASIC");
  useEffect(() => {
    switch (style) {
      case "BASIC":
        setGraphics(
          BasicPack({
            preview,
            animate,
            update,
            animateOff,
            triggerAnimation,
            clearPreview,
            previewTrio,
            getVizPage,
            updateVizPage,
            takeVizPage,
            takeOutVizPage,
            vizSharedMemory,
            vizCommand
          })
        );
        break;
      default:
    }
  }, [style]);

  const previewGraphic = useCallback(
    (name, options) => {
      if (graphics) {
        let graphic = graphics.find((graphic) => graphic.name === name);
        if (graphic) {
          graphic.preview({ options });
          if (graphic && graphic.name !== "CLOCK") {
            setGraphicInPreview(graphic);
          }
        }
      }
    },
    [preview, graphics]
  );

  const animateGraphic = useCallback(
    (name, options) => {
      let graphic = graphics.find((graphic) => graphic.name === name);
      if (graphic) {
        graphic.animate({ options });
      }
      // if (
      //   style === "SKY" &&
      //   graphic.name === "HeroFormation" &&
      //   graphic.stage === 14
      // ) {
      //   setGraphicInPreview();
      // }
    },
    [animate, graphics]
  );

  const animateOffGraphic = useCallback(
    (name, options) => {
      let graphic = graphics.find((graphic) => graphic.name === name);
      if (graphic) {
        graphic.animateOff({ options });
        setGraphicInPreview();
      }
    },
    [animate, graphics]
  );

  const updateGraphic = useCallback(
    (name, options) => {
      if (graphics) {
        let graphic = graphics.find((graphic) => graphic.name === name);
        if (graphic) {
          graphic.update({ options });
        }
      }
    },
    [animate, graphics]
  );

  const clearPVW = useCallback(
    (name) => {
      clearPreview(name);
      setGraphicInPreview();
    },
    [graphicInPreview, clearPreview]
  );

  return (
    <GraphicsContext.Provider
      value={{
        previewGraphic,
        animateGraphic,
        graphicInPreview,
        animateOffGraphic,
        updateGraphic,
        clearPVW,
      }}
    >
      {props.children}
    </GraphicsContext.Provider>
  );
};

export { GraphicsContext, GraphicsProvider };
