import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const CATEGORIES = [
  "Landed Arm Strikes",
  "Landed Leg Strikes",
  "Landed Ground Strikes",
  "Takedown Success",
  "Submission Success",
  "Missed Arm Strikes",
  "Missed Leg Strikes",
  "Missed Ground Strikes",
  "Takedown Attempted",
  "Submission Attempted",
  "Knockdowns",
];

const CATEGORIES_CIQ = ["TOT", "ARM", "LEG", "GD", "TD", "KD", "SUB"];

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  color: white;
  th {
    padding: 10px;
    text-align: center;
  }
  td {
    padding: 15px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 80px;
    border-bottom: 1px solid black;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  text-align: left;
`;

const TR = styled.tr`
  background-color: ${(props) =>
    props.title ? "#343e4e" : props.odd ? "#222e42" : ""};
  > td:nth-child(2),
  > td:nth-child(4),
  > td:nth-child(6) {
    background-color: ${(props) => (!props.title ? "#932c2c" : "")};
  }
  > td:nth-child(3),
  > td:nth-child(5),
  > td:nth-child(7) {
    background-color: ${(props) => (!props.title ? "#222274" : "")};
  }
`;
export default function NonStrikeStatsCIQ({ game }) {
  let period = useMemo(() => {
    let p = { number: game?.ciq_stats?.Curr_round };

    return p;
  }, [game]);

  function calTotal({ variable, stats, round }) {
    return (
      stats
        ?.filter((r) => r?.round === round || !round)
        ?.reduce((prev, curr) => {
          var value = variable.split(".").reduce((a, b) => a?.[b], curr);
          return prev + (value || 0);
        }, 0) || 0
    );
  }
  return (
    game && (
      <Main>
        <Table>
          <thead>
            <tr>
              <TH></TH>
              <TH colSpan={2}>Current Round (Match Totals)</TH>
            </tr>
            <tr>
              <TH>NON-STRIKE STATS</TH>
              <TH>{game?.home_fighter?.last_name}</TH>
              <TH>{game?.away_fighter?.last_name}</TH>
            </tr>
          </thead>
          <tbody>
            <TR odd={true}>
              <td>Knockdowns</td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[5] + ".Succ",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}{" "}
                (
                {calTotal({
                  variable: CATEGORIES_CIQ[5] + ".Succ",
                  stats: game?.home_player_ciq_stats,
                })}
                )
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[5] + ".Succ",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}{" "}
                (
                {calTotal({
                  variable: CATEGORIES_CIQ[5] + ".Succ",
                  stats: game?.away_player_ciq_stats,
                })}
                )
              </td>
            </TR>
            <TR>
              <td>Takedown Attempts</td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[4] + ".Att",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}{" "}
                (
                {calTotal({
                  variable: CATEGORIES_CIQ[4] + ".Att",
                  stats: game?.home_player_ciq_stats,
                })}
                )
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[4] + ".Att",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}{" "}
                (
                {calTotal({
                  variable: CATEGORIES_CIQ[4] + ".Att",
                  stats: game?.away_player_ciq_stats,
                })}
                )
              </td>
            </TR>
            <TR odd={true}>
              <td>Takedowns</td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[4] + ".Succ",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}{" "}
                (
                {calTotal({
                  variable: CATEGORIES_CIQ[4] + ".Succ",
                  stats: game?.home_player_ciq_stats,
                })}
                )
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[4] + ".Succ",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}{" "}
                (
                {calTotal({
                  variable: CATEGORIES_CIQ[4] + ".Succ",
                  stats: game?.away_player_ciq_stats,
                })}
                )
              </td>
            </TR>
            <TR>
              <td>Submission Attempts</td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[6] + ".Att",
                  stats: game?.home_player_ciq_stats,
                  round: period?.number,
                })}{" "}
                (
                {calTotal({
                  variable: CATEGORIES_CIQ[6] + ".Att",
                  stats: game?.home_player_ciq_stats,
                })}
                )
              </td>
              <td>
                {calTotal({
                  variable: CATEGORIES_CIQ[6] + ".Att",
                  stats: game?.away_player_ciq_stats,
                  round: period?.number,
                })}{" "}
                (
                {calTotal({
                  variable: CATEGORIES_CIQ[6] + ".Att",
                  stats: game?.away_player_ciq_stats,
                })}
                )
              </td>
            </TR>
            <TR odd={true}>
              <td>Clinch Time</td>
              <td style={{ backgroundColor: "#181d25" }} colSpan={2}>
                {(
                  Math.round(game?.ciq_stats?.R_all?.CL_time * 100) / 100
                ).toFixed(2)}
              </td>
            </TR>
            <TR odd={true}>
              <td>Ground Time</td>
              <td style={{ backgroundColor: "#181d25" }} colSpan={2}>
                {(
                  Math.round(game?.ciq_stats?.R_all?.GD_time * 100) / 100
                ).toFixed(2)}
              </td>
            </TR>
          </tbody>
        </Table>
      </Main>
    )
  );
}
