import moment from "moment";

const CATEGORIES = [
  "Landed Arm Strikes",
  "Landed Leg Strikes",
  "Landed Ground Strikes",
  "Takedown Success",
  "Submission Success",
  "Missed Arm Strikes",
  "Missed Leg Strikes",
  "Missed Ground Strikes",
  "Takedown Attempted",
  "Submission Attempted",
  "Knockdowns",
];

const GFX_CATEGORIES = [
  {
    name: "Arm Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[5],
        landed: CATEGORIES[0],
        stats,
      });
    },
  },
  {
    name: "Leg Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[6],
        landed: CATEGORIES[1],
        stats,
      });
    },
  },
  {
    name: "Ground Strikes",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[7],
        landed: CATEGORIES[2],
        stats,
      });
    },
  },
  {
    name: "Knockdowns",
    stat: (stats) => {
      return (
        stats?.reduce((prev, curr) => {
          return prev + (curr?.[CATEGORIES[10]] || 0);
        }, 0) || 0
      );
    },
  },
  {
    name: "Takedowns",
    stat: (stats) => {
      return calPercentage({
        missed: CATEGORIES[8],
        landed: CATEGORIES[3],
        stats,
      });
    },
  },
];

const calPercentage = function ({ missed, landed, stats, round }) {
  let missed_total = stats
    ?.filter((r) => r.round === round || !round)
    ?.reduce((prev, curr) => {
      return prev + (curr?.[missed] || 0);
    }, 0);
  let landed_total = stats
    ?.filter((r) => r.round === round || !round)
    ?.reduce((prev, curr) => {
      return prev + (curr?.[landed] || 0);
    }, 0);
  let output = (landed_total || 0) + "/";

  output += (missed_total || 0) + (landed_total || 0);

  return output;
};

function calcTotalStrikesThrown({ stats, round }) {
  return (
    stats
      ?.filter((r) => r.round === round || !round)
      ?.reduce((prev, curr) => {
        return (
          prev +
          (curr?.[CATEGORIES[0]] || 0) +
          (curr?.[CATEGORIES[1]] || 0) +
          (curr?.[CATEGORIES[2]] || 0) +
          (curr?.[CATEGORIES[5]] || 0) +
          (curr?.[CATEGORIES[6]] || 0) +
          (curr?.[CATEGORIES[7]] || 0)
        );
      }, 0) || 0
  );
}

function calcTotalStrikesLanded({ stats, round }) {
  let total =
    stats
      ?.filter((r) => r.round === round || !round)
      ?.reduce((prev, curr) => {
        return (
          prev +
          (curr?.[CATEGORIES[0]] || 0) +
          (curr?.[CATEGORIES[1]] || 0) +
          (curr?.[CATEGORIES[2]] || 0)
        );
      }, 0) || 0;

  return total;
}

const CATEGORIES_CIQ = ["TOT", "ARM", "LEG", "GD", "TD", "KD", "SUB"];

const GFX_CATEGORIES_CIQ = [
  {
    name: "Arm Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[1] + ".Succ",
        missed: CATEGORIES_CIQ[1] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Leg Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[2] + ".Succ",
        missed: CATEGORIES_CIQ[2] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Ground Strikes",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[3] + ".Succ",
        missed: CATEGORIES_CIQ[3] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Knockdowns",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[5] + ".Succ",
        missed: CATEGORIES_CIQ[5] + ".Att",
        stats: stats,
      });
    },
  },
  {
    name: "Takedowns",
    stat: (stats) => {
      return calPercentageCIQ({
        landed: CATEGORIES_CIQ[4] + ".Succ",
        missed: CATEGORIES_CIQ[4] + ".Att",
        stats: stats,
      });
    },
  },
];

function calPercentageCIQ({ missed, landed, stats, round }) {
  let missed_total = stats
    ?.filter((r) => r?.round === round || !round)
    ?.reduce((prev, curr) => {
      var value = missed?.split(".").reduce((a, b) => a?.[b], curr);

      return prev + (value || 0);
    }, 0);
  let landed_total = stats
    ?.filter((r) => r?.round === round || !round)
    ?.reduce((prev, curr) => {
      var value = landed?.split(".").reduce((a, b) => a?.[b], curr);
      return prev + (value || 0);
    }, 0);
  let output = (landed_total || 0) + "/";

  output += (missed_total || 0) + (landed_total || 0);

  return output;
}

export class Cagenomics {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "FFStats";
    this.scene = "FFStats";
    this.data = [];
    this.page = 9001;
  }

  async preview({ options }) {
    this.onAir = false;

    await this.updateData({ options, show: 1 });
    // await this.updateData({ options, show: 2 });
    // await this.updateData({ options, show: 3 });
    // await this.updateData({ options, show: 4 });
    await this.sendPreview("page:read " + this.page);
  }
  async animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }

  async updateData({ options, show }) {
    let xml = await this.getVizPage({ page: this.page, show }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "1100";
      }).value = options?.game?.home_fighter?.first_name.toUpperCase();

      xml.payload.field.find((item) => {
        return item.attr.name === "1101";
      }).value = options?.game?.home_fighter?.last_name.toUpperCase();

      xml.payload.field.find((item) => {
        return item.attr.name === "1104"; // Number
      }).value =
        "IMAGE*/PFL2023/_Globals/ScoreboardFlags/" +
        (options.game?.home_fighter?.nationality?.code || "");

      try {
        xml.payload.field.find((item) => {
          return item.attr.name === "1105"; // Number
        }).value =
          "IMAGE*/PFL2023/_Globals/" +
          (options.game?.home_fighter?.image_2 || "");
      } catch (err) {}
      xml.payload.field.find((item) => {
        return item.attr.name === "1107"; // Number
      }).value =
        "IMAGE*/PFL2023/_Globals/Fighter_Full/" +
        (options.game?.home_fighter?.image || "");

      xml.payload.field.find((item) => {
        return item.attr.name === "1200";
      }).value = options?.game?.away_fighter?.first_name.toUpperCase();

      xml.payload.field.find((item) => {
        return item.attr.name === "1201";
      }).value = options?.game?.away_fighter?.last_name?.toUpperCase();

      xml.payload.field.find((item) => {
        return item.attr.name === "1204"; // Number
      }).value =
        "IMAGE*/PFL2023/_Globals/ScoreboardFlags/" +
        (options.game?.away_fighter?.nationality?.code || "");
      try {
        xml.payload.field.find((item) => {
          return item.attr.name === "1205"; // Number
        }).value =
          "IMAGE*/PFL2023/_Globals/" +
          (options.game?.away_fighter?.image_2 || "");
      } catch (err) {}
      xml.payload.field.find((item) => {
        return item.attr.name === "1207"; // Number
      }).value =
        "IMAGE*/PFL2023/_Globals/Fighter_Full/" +
        (options.game?.away_fighter?.image || "");

      //TOTAL STRIKES

      xml.payload.field.find((item) => {
        return item.attr.name === "1410";
      }).value =
        calcTotalStrikesLanded({ stats: options?.game?.home_player_stats }) +
        "/" +
        calcTotalStrikesThrown({ stats: options?.game?.home_player_stats });

      xml.payload.field.find((item) => {
        return item.attr.name === "1430";
      }).value =
        calcTotalStrikesLanded({ stats: options?.game?.away_player_stats }) +
        "/" +
        calcTotalStrikesThrown({ stats: options?.game?.away_player_stats });

      xml.payload.field.find((item) => {
        return item.attr.name === "1412";
      }).value =
        GFX_CATEGORIES.find((s) => s.name === "Arm Strikes")?.stat(
          options?.game?.home_player_stats
        ) || "0/0";

      xml.payload.field.find((item) => {
        return item.attr.name === "1432";
      }).value =
        GFX_CATEGORIES.find((s) => s.name === "Arm Strikes")?.stat(
          options?.game?.away_player_stats
        ) || "0/0";

      xml.payload.field.find((item) => {
        return item.attr.name === "1414";
      }).value =
        GFX_CATEGORIES.find((s) => s.name === "Leg Strikes")?.stat(
          options?.game?.home_player_stats
        ) || "0/0";

      xml.payload.field.find((item) => {
        return item.attr.name === "1434";
      }).value =
        GFX_CATEGORIES.find((s) => s.name === "Leg Strikes")?.stat(
          options?.game?.away_player_stats
        ) || "0/0";

      xml.payload.field.find((item) => {
        return item.attr.name === "1416";
      }).value =
        GFX_CATEGORIES.find((s) => s.name === "Ground Strikes")?.stat(
          options?.game?.home_player_stats
        ) || "0/0";

      xml.payload.field.find((item) => {
        return item.attr.name === "1436";
      }).value =
        GFX_CATEGORIES.find((s) => s.name === "Ground Strikes")?.stat(
          options?.game?.away_player_stats
        ) || "0/0";

      xml.payload.field.find((item) => {
        return item.attr.name === "1418";
      }).value =
        GFX_CATEGORIES.find((s) => s.name === "Takedowns")?.stat(
          options?.game?.home_player_stats
        ) || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "1438";
      }).value =
        GFX_CATEGORIES.find((s) => s.name === "Takedowns")?.stat(
          options?.game?.away_player_stats
        ) || "0";

      await this.updateVizPage({
        page: this.page,
        data: xml,
        escape: false,
        show,
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  update({ options }) {}
}
