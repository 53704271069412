import React, { useRef, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid #202731;
  background-color: #1c222b;
`;
const StatDiv = styled.div`
  display: flex;
`;
const Category = styled.div`
  display: flex;
  min-width: 60%;
  justify-content: center;
  padding: 0.5em;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#db0a41" : "")};
`;
const Value = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 2px;
  align-items: center;
  font-size: ${(props) => (props.small ? ".8em" : "")};
  :hover {
    background-color: #db0a41;
  }
`;
const Bar = styled.div`
  display: flex;
  width: 100%;
`;
const InnerBar = styled.div`
  display: flex;
`;
const Bars = styled.div`
  display: flex;
  width: 100%;
`;

const usePrevious = function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default function Stat({
  category,
  stat,
  updateStat,
  updateHomeStat,
  updateAwayStat,
  previewGraphic,
  game,
  selectedStat,
}) {
  const previousHomeValue = usePrevious((stat && stat.home) || 0);
  const previousAwayValue = usePrevious((stat && stat.away) || 0);
  const [homeTimeout, setHomeTimeout] = useState();
  const [awayTimeout, setAwayTimeout] = useState();

  const homeRef = useRef();
  const awayRef = useRef();
  let homePercentage =
    (stat && (stat.home / (stat.home + stat.away)) * 100) || 0;
  let awayPercentage =
    (stat && (stat.away / (stat.home + stat.away)) * 100) || 0;

  let homeBGColour = game.home_team?.variables?.["home_kit_hex"] || "";
  let awayBGColour = game.away_team?.variables?.["home_kit_hex"] || "";

  useEffect(() => {
    if (stat) {
      if (homeRef.current && awayRef.current) {
        if (stat && previousHomeValue && stat.home !== previousHomeValue) {
          homeRef.current.style.backgroundColor = "#db0a41";

          clearTimeout(homeTimeout);
          setHomeTimeout(
            setTimeout(() => {
              if (homeRef && homeRef.current)
                homeRef.current.style.backgroundColor = "";
            }, 2000)
          );
        }
        if (stat && previousAwayValue && stat.away !== previousAwayValue) {
          awayRef.current.style.backgroundColor = "#db0a41";
          clearTimeout(awayTimeout);
          setAwayTimeout(
            setTimeout(() => {
              if (awayRef && awayRef.current)
                awayRef.current.style.backgroundColor = "";
            }, 2000)
          );
        }
      }
    }
  }, [stat]);

  return (
    <Main>
      <StatDiv>
        <Value
          ref={homeRef}
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            updateHomeStat({ e });
          }}
        >
          {stat && category !== "Possession" && (stat.home || 0)}
          {stat && category === "Possession" && Math.round(stat.home) + "%"}
        </Value>
        <Category
        // selected={selectedStat === category}
        // onClick={() => {
        //   let newStat = { ...stat };
        //   if (category === "Possession") {
        //     newStat.home = Math.round(newStat.home) + "%";
        //     newStat.away = Math.round(newStat.away) + "%";
        //   }

        //   previewGraphic("TeamStat", {
        //     stat: { ...newStat, category: category },
        //     game,
        //   });
        // }}
        >
          {category}
        </Category>
        <Value
          ref={awayRef}
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            updateAwayStat({ e });
          }}
        >
          {stat && category !== "Possession" && (stat.away || 0)}
          {stat && category === "Possession" && Math.round(stat.away) + "%"}
        </Value>
      </StatDiv>
      <Bars>
        <Bar style={{ justifyContent: "flex-end" }}>
          <InnerBar
            style={{
              width: homePercentage + "%",
              height: "2px",
              backgroundColor: homeBGColour ? homeBGColour : "rgba(0,0,0,0)",
            }}
          />
        </Bar>
        <Bar>
          <InnerBar
            style={{
              width: awayPercentage + "%",
              height: "2px",
              backgroundColor: awayBGColour ? awayBGColour : "rgba(0,0,0,0)",
            }}
          />
        </Bar>
      </Bars>
    </Main>
  );
}
